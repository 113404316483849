@charset "UTF-8";
.tx-kesearch-pi1 {
  font-family: arial, sans-serif;
  font-size: 14px; }

/* Clearer */
.tx-kesearch-pi1 .clearer {
  display: block;
  height: 1px;
  line-height: 1px;
  clear: both; }

/* formElements */
.tx-kesearch-pi1 form {
  border: 0 none;
  margin: 0 0 20px 0; }

.tx-kesearch-pi1 form fieldset {
  margin: 0;
  padding: 0; }

.tx-kesearch-pi1 .kesearch_searchbox {
  border: none; }

.tx-kesearch-pi1 input[type="text"],
.tx-kesearch-pi1 select,
.tx-kesearch-pi1 textarea {
  padding: 0.3em 0.3em 0.3em 0.4em;
  background: white;
  border: 1px solid #bbbbbb;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: #2b2b2b; }

.tx-kesearch-pi1 input[type="text"]:focus,
.tx-kesearch-pi1 input[type="text"]:hover,
.tx-kesearch-pi1 select:focus,
.tx-kesearch-pi1 select:hover,
.tx-kesearch-pi1 textarea:focus,
.tx-kesearch-pi1 textarea:hover {
  box-shadow: 0 0 3px #bbbbbb;
  border: 1px solid #b2b2b2;
  color: #000; }

/* Buttons */
span.resetbutt a,
span.submitbutt input,
input.kesGreyButt,
span.kesGreyButt {
  padding: 5px 10px;
  margin: 0 10px 10px 0;
  display: block;
  cursor: pointer;
  min-width: 70px;
  max-width: 140px;
  -moz-user-select: none;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(center top, #f6f6f6, #f0f0f0);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: #444;
  cursor: default;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: none; }

/* fix firefox button size (line-height is not working in firefox submit button)*/
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

li.checkboxButtonSubmit span,
span.submitbutt input,
input.kesGreyButt[type="submit"] {
  min-width: 169px;
  max-width: 220px; }

span.resetbutt {
  float: left; }

span.resetbutt a:hover,
span.submitbutt input:hover,
input.kesGreyButt:hover,
span.kesGreyButt:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  background: #d1d1d1;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(center top, #f8f8f8, #f1f1f1);
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #222; }

span.kesGreyButt a {
  text-decoration: none;
  color: #444;
  text-shadow: 0px 1px 0px #ffffff; }

span.kesGreyButt a:hover {
  text-decoration: none;
  color: #000;
  text-shadow: 0px -1px 0px #ffffff; }

/* buttFixes */
#kesearch_filters .list li.checkboxButton,
#kesearch_filters .list li.checkboxButtonSubmit {
  text-decoration: none !important;
  padding-bottom: 5px; }

#kesearch_filters .list li.checkboxButtonSubmit {
  margin-bottom: 1px; }

#kesearch_filters .list li.checkboxButtonSubmit {
  padding-bottom: 0; }

/* SearchBox */
div.kesearchbox {
  position: relative;
  height: 35px;
  margin: 0 0 10px 0; }

#ke_search_sword {
  position: absolute;
  top: 1px;
  width: 220px;
  height: auto;
  padding: 8px 40px 7px 9px;
  margin: 0;
  display: inline; }

#kesearch_submit {
  position: absolute;
  top: 3px;
  left: 237px;
  height: 28px;
  width: 28px;
  border: 0 none;
  padding: 0; }

#kesearch_submit:focus,
#kesearch_submit:hover {
  box-shadow: 0 0 0 white;
  border: 0 none;
  cursor: pointer; }

/* Filters */
#kesearch_filters div.textlinks {
  margin: 0 0 20px 0; }

#kesearch_filters select {
  min-width: 180px;
  max-width: 270px;
  margin: 0 0 10px 0; }

#kesearch_filters .list {
  margin-bottom: 10px; }

#kesearch_filters .list .head {
  background: url("img/filterHeadBG.gif");
  display: block;
  border: 1px solid #CFCFCF;
  padding: 5px; }

#kesearch_filters .list .head img {
  margin: 0 5px;
  position: relative;
  top: -2px; }

#kesearch_filters .list ul {
  padding: 10px;
  margin: 0;
  border: 1px solid #cfcfcf;
  border-top: 0 none;
  list-style-type: none; }

#kesearch_filters .list ul.expanded {
  display: block; }

#kesearch_filters .list ul.closed {
  display: none; }

#kesearch_filters .list li {
  display: block;
  list-style-type: none;
  margin: 0 0 10px 0;
  line-height: 16px;
  color: #444; }

#kesearch_filters .list li a {
  color: #444; }

#kesearch_filters .list li:hover,
#kesearch_filters .list li a:hover {
  text-decoration: none;
  color: #444;
  cursor: pointer; }

#kesearch_filters .list li.selected {
  font-weight: bold; }

#kesearch_filters .list li.parent0,
#kesearch_filters .list li.level0 {
  margin-left: 14px; }

#kesearch_filters .list li.parent1,
#kesearch_filters .list li.level1 {
  margin-left: 21px; }

#kesearch_filters .list li.parent2,
#kesearch_filters .list li.level2 {
  margin-left: 28px; }

#kesearch_filters .list li.parent3,
#kesearch_filters .list li.level3 {
  margin-left: 35px; }

#kesearch_filters .list li.parent0,
#kesearch_filters .list li.parent1,
#kesearch_filters .list li.parent2,
#kesearch_filters .list li.parent3 {
  font-weight: bold;
  list-style-image: url("img/list-bullet-cat.gif");
  list-style-position: outside; }

/* Checkboxes */
#kesearch_filters .list ul.checkboxList {
  padding-bottom: 1px; }

#kesearch_filters .list li.optionCheckBox {
  float: left;
  width: 200px;
  text-decoration: none;
  margin-bottom: 4px; }

#kesearch_filters .list li.optionCheckBox input[type="checkbox"] {
  float: left;
  margin: 0 5px 0 0; }

#kesearch_filters .list li.clearer {
  font-size: 0;
  line-height: 0;
  float: none;
  margin: 0 !important; }

#kesearch_filters label {
  display: block;
  font-weight: bold;
  color: #353535;
  margin: 0 0 4px 0; }

#kesearch_filters .list li.region {
  font-weight: bold;
  margin-top: 5px; }

#kesearch_filters .list li.noclick {
  cursor: default !important; }

#kesearch_filters .list li.country {
  margin-left: 7px; }

/* SPECIAL CSS CLASS */
#kesearch_filters .small {
  display: block;
  height: 65px;
  max-height: 65px;
  overflow-y: auto;
  overflow-x: hidden; }

#kesearch_filters .larger {
  display: block;
  height: 130px;
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden; }

/* multiselectPage */
.tx-kesearch-pi1 .multiselect .multi-option {
  float: left;
  width: 200px;
  margin: 0 0 5px 0; }

.tx-kesearch-pi1 .multiselect .multi-option input[type="checkbox"] {
  float: left;
  margin: 0 5px 0 0; }

.tx-kesearch-pi1 .multiselect label {
  font-weight: normal;
  padding: 0 10px 0 0;
  float: left; }

.tx-kesearch-pi1 .multiselect .multiselectButtons {
  padding: 10px 0 0 0; }

.tx-kesearch-pi1 .multiselect .last {
  /* ???? */ }

/* Results */
#kesearch_num_results {
  color: #898989;
  max-width: 150px;
  float: left; }

#kesearch_results .result-list-item {
  clear: both;
  margin: 20px 0 30px 0;
  padding: 0; }

#kesearch_results .result-list-item .result-number,
#kesearch_results .result-list-item .result-title {
  font-size: 18px; }

#kesearch_results {
  clear: both;
  margin-top: 20px; }

#kesearch_results .result-list-item .result-teaser {
  color: #707070; }

.tx-kesearch-pi1 #kesearch_results .teaser_icon img {
  float: left;
  margin-top: 2px;
  margin-right: 5px; }

.tx-kesearch-pi1 #kesearch_results .result-list-item .hit {
  color: red;
  font-weight: bold; }

.tx-kesearch-pi1 #kesearch_results .result-list-item .scoreBar {
  border: 1px solid #D3D3D3;
  display: block;
  float: right;
  height: 10px;
  width: 48px; }

.tx-kesearch-pi1 #kesearch_results .result-list-item .scoreBar .score {
  background-color: #D3D3D3;
  border: 1px solid #898989;
  display: block;
  height: 10px;
  margin: -1px 0 0 -1px; }

/* Query time */
.tx-kesearch-pi1 #kesearch_query_time {
  font-style: italic;
  text-align: center;
  font-size: 80%; }

/* Searchbox */
.tx-kesearch-pi1 .searchbox {
  margin-bottom: 5px; }

.tx-kesearch-pi1 .searchbox input {
  margin-left: 0;
  width: 145px; }

.tx-kesearch-pi1 .searchbox input.submit {
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  vertical-align: middle; }

.tx-kesearch-pi1 form.static .searchbox input.submit {
  margin-top: -16px; }

.tx-kesearch-pi1 form.ajax .searchbox input.submit {
  margin-top: -4px; }

.tx-kesearch-pi1 .searchbox .search_input {
  float: left;
  margin-right: 5px; }

/* Spinner / updating */
.tx-kesearch-pi1 #kesearch_updating_filters,
.tx-kesearch-pi1 #kesearch_updating_results {
  display: none;
  text-align: center; }

.tx-kesearch-pi1 #testbox {
  margin-top: 100px;
  border: 1px solid red; }

/* General Message */
.tx-kesearch-pi1 .general-message {
  padding: 5px; }

.tx-kesearch-pi1 .general-message .image {
  float: left;
  vertical-align: middle;
  margin-right: 10px; }

/* Pagebrowser */
#kesearch_pagebrowser_top,
#kesearch_pagebrowser_bottom {
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  color: #707070; }

#kesearch_pagebrowser_top .pages_total,
#kesearch_pagebrowser_bottom .pages_total {
  padding: 8px 0;
  background: #fff;
  margin-bottom: 15px; }

#kesearch_pagebrowser_top .pages_total {
  border-bottom: 1px dashed #707070; }

#kesearch_pagebrowser_bottom .pages_total {
  border-top: 1px dashed #707070; }

#kesearch_pagebrowser_top div.result_txt, #kesearch_pagebrowser_bottom div.result_txt {
  margin: 0 0 5px 0; }

#kesearch_pagebrowser_top .kesearch_pagebrowser, #kesearch_pagebrowser_bottom .kesearch_pagebrowser {
  margin: 0 auto 0; }

#kesearch_pagebrowser_top a, #kesearch_pagebrowser_bottom a {
  padding: 3px; }
  #kesearch_pagebrowser_top a:hover, #kesearch_pagebrowser_bottom a:hover {
    cursor: pointer; }
  #kesearch_pagebrowser_top a.current, #kesearch_pagebrowser_bottom a.current {
    font-weight: bold;
    color: #000;
    text-decoration: none; }

#kesearch_pagebrowser_top .browser, #kesearch_pagebrowser_bottom .browser {
  border: 1px solid; }

#kesearch_pagebrowser_top .kesearch_pagebrowser ul, #kesearch_pagebrowser_top .kesearch_pagebrowser ul li, #kesearch_pagebrowser_bottom .kesearch_pagebrowser ul, #kesearch_pagebrowser_bottom .kesearch_pagebrowser ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center; }

#kesearch_pagebrowser_top .kesearch_pagebrowser li, #kesearch_pagebrowser_bottom .kesearch_pagebrowser li {
  display: inline-block; }

.tx-kesearch-pi1 .next,
.tx-kesearch-pi1 .prev {
  display: inline-block;
  width: 24px;
  height: 29px;
  color: transparent;
  text-decoration: none;
  overflow: hidden; }

.tx-kesearch-pi1 .next {
  background: transparent url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/arrow-next.gif") no-repeat left 0; }

.tx-kesearch-pi1 .prev {
  background: transparent url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/arrow-prev.gif") no-repeat right 0; }

.tx-kesearch-pi1 a.next,
.tx-kesearch-pi1 a.prev {
  display: inline-block;
  width: 24px;
  height: 29px;
  color: transparent;
  text-decoration: none;
  padding: 0 !important;
  margin: 0 0 -10px 0; }

/* Ordering */
.tx-kesearch-pi1 #kesearch_ordering span.down {
  display: inline-block;
  width: 0;
  height: 0;
  border-color: #FF0000 transparent;
  border-style: solid solid none;
  border-width: 4px 4px 0; }

.tx-kesearch-pi1 #kesearch_ordering span.up {
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent transparent #FF0000;
  border-style: none solid solid;
  border-width: 0 4px 4px; }

.tx-kesearch-pi1 #kesearch_ordering div.ordering {
  float: right;
  max-width: 300px; }

.tx-kesearch-pi1 #kesearch_ordering ul {
  display: inline-block;
  height: 20px;
  list-style-type: none;
  margin: 0 0 10px;
  padding: 0; }

.tx-kesearch-pi1 #kesearch_ordering ul li {
  float: left;
  margin-right: 10px; }

.tx-kesearch-pi1 #kesearch_ordering div.clearer {
  clear: left; }

/* needed for autocomplete */
ul.ui-autocomplete li a {
  font-size: 12px; }

/* Default style sheet for SrLanguageMenu */
.tx-srlanguagemenu, .tx-srlanguagemenu .links-list-header {
  font-family: Verdana, sans-serif;
  line-height: 100%;
  margin-top: 8px;
  padding: 0;
  text-indent: 0; }

.tx-srlanguagemenu .CUR,
.tx-srlanguagemenu .NO,
.tx-srlanguagemenu .INACT {
  float: left;
  font-size: .8em;
  margin: 0 5px; }
  .tx-srlanguagemenu .CUR a,
  .tx-srlanguagemenu .NO a,
  .tx-srlanguagemenu .INACT a {
    background-color: transparent;
    font-weight: bold;
    text-decoration: none; }
    .tx-srlanguagemenu .CUR a:focus, .tx-srlanguagemenu .CUR a:hover, .tx-srlanguagemenu .CUR a:active,
    .tx-srlanguagemenu .NO a:focus,
    .tx-srlanguagemenu .NO a:hover,
    .tx-srlanguagemenu .NO a:active,
    .tx-srlanguagemenu .INACT a:focus,
    .tx-srlanguagemenu .INACT a:hover,
    .tx-srlanguagemenu .INACT a:active {
      color: #333333; }

.tx-srlanguagemenu .CUR a.flag:before,
.tx-srlanguagemenu .CUR a.linked-language:before {
  content: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/languages/current.png");
  padding-right: 3px; }

.tx-srlanguagemenu-links .SPC:before {
  content: "|";
  padding-right: 3px; }

.tx-srlanguagemenu .tx-srlanguagemenu-flag-linked,
.tx-srlanguagemenu .tx-srlanguagemenu-flag-unlinked {
  height: 13px;
  width: 20px; }

.tx-srlanguagemenu img.tx-srlanguagemenu-flag-unlinked {
  filter: grayscale(100%);
  /* Current draft standard */
  -webkit-filter: grayscale(100%);
  /* New WebKit */
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  /* Not yet supported in Gecko, Opera or IE */
  filter: url("data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'><filter%20id='grayscale'><feColorMatrix%20type='matrix'%20values='0.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200%200%200%201%200'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  filter: gray;
  /* IE */
  -webkit-filter: grayscale(1);
  /* Old WebKit */ }

.tx-srlanguagemenu fieldset legend {
  /*background: url(img('Images/languages/language.png')) no-repeat 2px 50%;
      padding: 0 2px 2px 20px;*/ }

.tx-srlanguagemenu fieldset label {
  display: none; }

.tx-srlanguagemenu fieldset img.tx-srlanguagemenu-language-icon {
  margin-right: 3px;
  vertical-align: middle; }

.tx-srlanguagemenu fieldset select {
  background-color: #009933;
  color: white;
  font-size: .8em; }

.tx-srlanguagemenu fieldset option {
  padding: 2px 5px 0 2px; }

/* CSS Document */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0; }

html, body {
  font-family: Arial, sans-serif;
  height: 100%;
  background: #29ABE2; }

header,
nav:not(.frame-default),
footer {
  display: block; }

form.powermail_form ul {
  right: 19px !important;
  bottom: 0px !important;
  opacity: 0.7 !important; }

hr {
  border: 0px;
  background: #000;
  height: 1px;
  margin: 20px 0px 30px 0px;
  clear: both; }

.align-right {
  text-align: right; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.tx-odsajaxmailsubscription-pi1 a {
  color: #fff !important;
  text-decoration: underline; }

.tx-odsajaxmailsubscription-pi1 a:hover {
  font-weight: bold; }

.tx-odsajaxmailsubscription-pi1 input[name="tx_odsajaxmailsubscription_pi1[email]"] {
  width: 60% !important;
  display: inline-block !important; }

.tx-odsajaxmailsubscription-pi1 input[name="tx_odsajaxmailsubscription_pi1[submit]"] {
  width: 30% !important;
  display: inline-block !important;
  float: right !important;
  margin: 0 !important;
  height: 25px !important;
  padding: 6px !important; }

.nivo-imageLink {
  display: none; }

.powermail_confirmation,
.powermail_create {
  padding-left: 30px;
  font-size: 15px; }

.powermail_confirmation h4,
.powermail_create h4 {
  margin-bottom: 5px; }

.powermail_confirmation dd,
.powermail_confirmation dt,
.powermail_create dd,
.powermail_create dt {
  width: 50%;
  display: inline; }

.powermail_confirmation dd,
.powermail_create dd {
  margin-right: 100%; }

form.powermail_form .powermail_radio_inner {
  width: 25% !important; }

.table-white-training,
.frame-table-white-training {
  margin: 30px 0; }

.table-white-training a,
.frame-table-white-training a {
  color: #fcee21 !important; }

.table-white-training a:hover,
.frame-table-white-training a:hover {
  font-weight: bold;
  text-decoration: underline; }

.table-white-training img,
.frame-table-white-training img {
  width: auto !important;
  max-width: 180px !important; }

.table-white-training table,
.frame-table-white-training table {
  border: 1px solid #FFF;
  border-collapse: collapse;
  width: 100% !important;
  min-width: 800px;
  color: #FFF; }

.table-white-training table td,
.frame-table-white-training table td {
  padding: 15px; }

.table-white-training table td:first-of-type,
.frame-table-white-training table td:first-of-type {
  font-weight: bold; }

.clear {
  clear: both; }

f#akkordeon igcaption {
  margin-top: 5px; }

#kesearch_submit {
  display: none; }

#Language {
  position: absolute;
  top: -4px;
  color: #fff !important;
  background: rgba(41, 171, 226, 0.3); }

#akkordeon .ce-center .ce-gallery {
  margin: 0 auto; }

#Language .tx-srlanguagemenu .CUR a,
.tx-srlanguagemenu .NO a,
.tx-srlanguagemenu .INACT a {
  color: #fff !important; }

.bx-wrapper .bx-viewport {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.bx-wrapper .bx-loading {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.bx-wrapper img,
.bx-wrapper .ce-gallery,
header .header_pic .ce-gallery,
header .header_pic img,
.teaser-s .ce-gallery,
.frame-teaser-s .ce-gallery,
.teaser-s img,
.frame-teaser-s img,
.teaser-l .ce-gallery,
.frame-teaser-l .ce-gallery,
.teaser-l img,
.frame-teaser-l img {
  width: 100% !important;
  height: auto !important; }

.theme-default .nivo-controlNav {
  float: right;
  margin-right: 110px;
  padding: 4px 0; }

header .header_pic .ce-gallery,
header .header_pic figure,
.nivoSlider figure {
  padding: 0;
  margin: 0 !important; }

header .header_pic .frame-default {
  position: relative; }

header .header_pic .frame-default h1 {
  position: absolute;
  top: 62%;
  left: 35px;
  width: 80%;
  color: #fff;
  font-weight: bold;
  text-align: left;
  letter-spacing: 2px;
  font-size: 28px;
  line-height: 28px;
  z-index: 10000; }

#bilder1bis5 .ce-gallery {
  width: 100% !important; }

#bilder1bis5 .ce-gallery .image {
  width: 20% !important; }

#sec3-angebotsspektrum h3 a,
#sec4-angebotsspektrum h3 a {
  color: #fff; }

.angebotsspektrum #sec2bis3-standardsensoren #sec3-standardsensoren .csc-menu {
  margin-top: 42px; }

#vertretungen table {
  width: 100%; }

#vertretungen table td {
  width: 50%;
  vertical-align: bottom; }

/*
.frame-teaser-products .frame-teaser-product {
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
	margin-bottom: 1%;
}
.frame-teaser-products .frame-teaser-product .csc-header:first-of-type {
	-webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
*/
.teaser-products,
.frame-teaser-products {
  display: flex;
  flex-wrap: wrap; }

.teaser-products .teaser-product,
.frame-teaser-products .frame-teaser-product {
  display: flex;
  position: relative;
  margin-bottom: 62px; }

.teaser-products .teaser-product p,
.frame-teaser-products .frame-teaser-product p {
  width: 100%;
  margin-left: 15px;
  font-size: 12px; }

.teaser-products .teaser-product .csc-header,
.frame-teaser-products .frame-teaser-product .csc-header {
  clear: left;
  background-color: #005187;
  width: 97%;
  color: #fff !important;
  position: absolute;
  bottom: -54px;
  overflow: hidden;
  left: 0; }

#akkordeon .table-img-fest img {
  width: 300px !important;
  max-width: none !important; }

form input,
form textarea {
  width: 96%;
  color: #000;
  font-weight: bold;
  background-color: #eee;
  border: none;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 0.95em;
  font-style: italic; }

form input:hover,
form textarea:hover {
  font-style: normal; }

form label {
  color: #fff;
  font-size: 14px; }

form.powermail_form > fieldset > div {
  margin-bottom: 5px !important; }

form > div:first-of-type {
  display: block !important; }

form fieldset {
  border: 0 !important; }

form input[type=submit],
form input[type=reset] {
  width: 70%;
  margin: 0 auto;
  padding: 12px 0;
  color: #fff;
  font-weight: bold;
  background-color: #29ABE2;
  border: none;
  margin-top: 20px;
  display: block;
  font-style: normal; }

.ansprechpartner,
.frame-ansprechpartner {
  float: left;
  width: 49%;
  color: #000 !important;
  margin-bottom: 40px;
  margin-right: 1%;
  background-color: #c9e1f1;
  padding: 12px 9px;
  line-height: 22px; }

.ansprechpartner a,
.frame-ansprechpartner a {
  color: #000 !important; }

.ansprechpartner figure,
.frame-ansprechpartner figure {
  margin: 0 !important;
  padding: 0 !important; }

.teaser-products .teaser-product .ce-gallery,
.teaser-products .teaser-product .ce-gallery figure,
.teaser-products .teaser-product .ce-gallery img,
.frame-teaser-products .frame-teaser-product .ce-gallery,
.frame-teaser-products .frame-teaser-product .ce-gallery figure,
.frame-teaser-products .frame-teaser-product .ce-gallery img {
  width: 100% !important;
  height: auto !important; }

.teaser-products .teaser-product .csc-header a,
.frame-teaser-products .frame-teaser-product .csc-header a {
  display: block;
  color: #fff;
  text-align: center;
  font-size: 22px;
  padding: 14px 0;
  font-weight: normal; }

.teaser-products .teaser-product .csc-header a:after,
.frame-teaser-products .frame-teaser-product .csc-header a:after {
  content: "mehr >>";
  font-size: 13px;
  padding-left: 5px; }

#body_en .teaser-products .teaser-product .csc-header a:after,
#body_en .frame-teaser-products .frame-teaser-product .csc-header a:after {
  content: "more >>"; }

#body_fr .teaser-products .teaser-product .csc-header a:after,
#body_fr .frame-teaser-products .frame-teaser-product .csc-header a:after {
  content: "plus >>"; }

.teaser-products .teaser-product .ce-textpic,
.frame-teaser-products .frame-teaser-product .ce-textpic {
  background-color: #1d7fc1;
  width: 100%; }

.teaser-products .teaser-product figure,
.frame-teaser-products .frame-teaser-product figure {
  margin: 0 !important;
  padding: 0 !important; }

.teaser-products .teaser-product .ce-gallery,
.frame-teaser-products .frame-teaser-product .ce-gallery {
  margin-left: 0 !important; }

.teaser-products .teaser-product figcaption,
.frame-teaser-products .frame-teaser-product figcaption {
  min-height: 18px;
  width: 100%;
  background-image: linear-gradient(#3399cc, #336699); }

.teaser-products .teaser-product figcaption a,
.frame-teaser-products .frame-teaser-product figcaption a {
  float: right;
  width: 30px;
  height: 18px;
  display: block;
  cursor: pointer;
  text-indent: -100px;
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/pfeil1.jpg"); }

.teaser-products .teaser-product span.new,
.frame-teaser-products .frame-teaser-product span.new {
  width: 16%;
  margin: 0 auto;
  display: block;
  font-size: 12px;
  color: red;
  text-align: center;
  background-color: #fff;
  padding: 2px 7px;
  box-shadow: 0 0 10px #fff, 0 0 60px #fff, 0 0 150px #fff; }

.teaser-products .ce-bodytext,
.frame-teaser-products .ce-bodytext {
  background-color: #1d7fc1;
  width: 100%;
  line-height: 20px;
  z-index: 5000;
  padding: 2% 0% 2% 0%;
  clear: both; }

.teaser-products .ce-bodytext ul li,
.frame-teaser-products .ce-bodytext ul li {
  clear: both !important;
  width: 90% !important;
  list-style-type: square !important;
  padding: 0% !important;
  margin-left: 30px;
  font-size: 12px; }

.dreispaltig-acc table img,
.frame-dreispaltig-acc table img {
  width: auto !important;
  height: auto !important; }

.nivo-caption {
  position: absolute;
  left: 0px;
  bottom: 15px !important;
  background: #0f4163 !important;
  color: #fff;
  width: 100%;
  z-index: 8;
  padding: 10px;
  opacity: 0.8;
  text-align: center;
  padding: 10px !important;
  font-size: 25px;
  font-family: Arial, sans-serif !important;
  font-weight: normal !important;
  display: none;
  -moz-opacity: 0.8;
  filter: alpha(opacity=8);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible !important; }

.theme-default .nivo-caption a {
  color: #fff;
  border-bottom: none !important;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  text-indent: -9999px;
  background-color: transparent;
  height: 1000px;
  z-index: 8000; }

.theme-default .nivo-controlNav {
  float: right;
  margin-right: 110px;
  padding: 4px 0 !important; }

.theme-default .nivo-controlNav a {
  margin: 0 20px !important; }

.theme-default .nivoSlider {
  position: relative;
  background: #1d7fc0 no-repeat 50% 50% !important;
  box-shadow: none !important; }

#sitemap .frame-type-menu_sitemap {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px; }

#sitemap .frame-type-menu_sitemap > ul {
  font-size: 15px;
  font-weight: bold; }

#sitemap .frame-type-menu_sitemap > ul ul {
  margin-top: 5px !important; }

#sitemap .frame-type-menu_sitemap > ul ul ul {
  padding: 5px 20px 10px 20px;
  font-size: 14px;
  font-weight: normal;
  margin-top: 0px !important; }

#sitemap a {
  color: #003068 !important; }

#sitemap .csc-header {
  width: 100%; }

#inhalt-datenblaetter:nth-of-type(n+2) {
  margin-top: 0 !important; }

table a[href$=".pdf"] {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/pdf.jpg") no-repeat;
  text-indent: -9999px;
  width: 26px;
  background-position: center center;
  display: block;
  height: 26px;
  margin: 0 auto; }

table a[href$=".zip"],
table a[href$=".avi"],
table a[href$=".mpg"],
table a[href$=".GSD"] {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/disc.jpg") no-repeat;
  text-indent: -9999px;
  width: 26px;
  background-position: center center;
  display: block;
  height: 26px;
  margin: 0 auto; }

table a[href*="youtube"],
table a[href*="youtu.be"] {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/Video-icon.png") no-repeat;
  text-indent: -9999px;
  width: 26px;
  background-position: center center;
  display: block;
  height: 26px;
  margin: 0 auto; }

#inhalt-datenblaetter table tr th {
  height: 30px; }

#inhalt-datenblaetter table tr th,
#inhalt-datenblaetter table tr td {
  min-width: 50px; }

#inhalt-datenblaetter table tr td:nth-of-type(n+3) {
  text-align: center; }

table tr th:nth-of-type(n+3) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_de.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(4) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_uk.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(5) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_usa.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(6) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_fr.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(7) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_it.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(8) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_sp.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(9) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_nl.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(10) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_tr.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(11) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_hu.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

table tr th:nth-of-type(12) {
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/flagge_jp.jpg") no-repeat;
  text-indent: -9999px;
  background-position: center; }

#sec1bis3-index h3 a {
  color: #fff; }

#breadcrumb h4 {
  display: inline-block; }

#breadcrumb h4:nth-of-type(n+2) {
  padding: 1% 0px 3% 2px !important; }

#breadcrumb h4:before {
  content: "> ";
  padding-right: 2px; }

#breadcrumb h4:first-of-type:before {
  content: none; }

#content:not(.branchen):not(.dienstleistungen):not(.angebotsspektrum) .csc-menu ul a:before {
  content: "> "; }

#content:not(.branchen):not(.dienstleistungen):not(.angebotsspektrum) .csc-menu li a:hover {
  color: #fcee21;
  text-decoration: underline; }

#content:not(.branchen):not(.dienstleistungen):not(.angebotsspektrum) .csc-menu li {
  list-style-type: none; }

#content:not(.branchen):not(.dienstleistungen):not(.kontakt):not(.angebotsspektrum):not(.startseite) .csc-menu li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none; }

.unternehmen .csc-menu li {
  padding-top: 15px; }

.aktuelles .csc-menu a {
  color: #000 !important; }

.aktuelles #breadcrumb h4 {
  padding: 0% 2px 0% 1% !important; }

.aktuelles #breadcrumb h4:nth-of-type(n+2) {
  padding: 0% 0px 0% 2px !important; }

.aktuelles .text-aktuelles-li-wide .frame-default {
  margin-top: 30px;
  width: 100%; }

.aktuelles .text-aktuelles-li-wide .frame-default .ce-bodytext-with-gallery {
  float: left;
  width: 50%;
  padding-right: 2%; }

.aktuelles .text-aktuelles-li-wide .frame-default .ce-gallery-with-bodytext {
  float: right;
  width: 50% !important;
  padding-right: 1%;
  margin-left: 0 !important; }

#content.me #sec2-messen .frame-default .ce-bodytext {
  float: right;
  width: 65% !important;
  padding-left: 2% !important; }

#content.me #sec2-messen .frame-default .ce-gallery {
  float: left;
  width: 35% !important;
  margin-right: 0px !important; }

#content .tx-kesearch-pi1 form {
  display: none; }

#content #sec2-unternehmen ul {
  list-style-type: disc;
  padding: 5px 0px 5px 30px; }

#content #sec2-unternehmen ul li {
  padding: 5px 0; }

.table-white td,
.frame-table-white td {
  padding: 20px;
  border: 1px solid #fff; }

.table-white table,
.frame-table-white table {
  width: 100%;
  border: 1px solid #fff;
  border-spacing: 0px;
  border-collapse: collapse; }

.table-white ul,
.frame-table-white ul {
  padding: 10px 0px 10px 20px; }

.table-white li,
.frame-table-white li {
  padding: 5px 0; }

.dienstleistungen .csc-menu li {
  width: 15.66666666666667%;
  display: table;
  margin: 1% 1% 0 0;
  float: left;
  box-shadow: 2px 2px 5px #333; }

.dienstleistungen .csc-menu li:hover {
  box-shadow: 1px 1px 3px #333; }

.dienstleistungen .csc-menu li a {
  display: table-cell;
  font-size: 14px !important;
  color: #000;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(#ffffff, #a6bad2);
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  height: 60px;
  line-height: 16px;
  vertical-align: middle; }

.dienstleistungen .csc-menu li:last-of-type a {
  color: transparent; }

.angebotsspektrum .csc-menu li {
  font-weight: normal; }

#sec1-kontakt {
  padding: 0 1%; }

.kontakt .csc-menu li {
  width: 48%;
  display: table;
  margin-right: 2%;
  float: left; }

.kontakt .csc-menu li:last-of-type {
  margin-right: 0;
  width: 50%; }

.kontakt .csc-menu li a {
  display: table-cell;
  font-size: 16px !important;
  color: #000 !important;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(#ffffff, #a6bad2);
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  height: 60px;
  line-height: 16px;
  vertical-align: middle; }

.teaser-s,
.frame-teaser-s {
  width: 23.75%;
  margin-left: 1%;
  float: left;
  position: relative; }

.teaser-s .csc-header,
.frame-teaser-s .csc-header {
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center; }

.teaser-s .csc-header a,
.frame-teaser-s .csc-header a {
  font-size: 14px;
  font-weight: normal;
  color: #fff; }

.teaser-s .csc-header a:hover,
.frame-teaser-s .csc-header a:hover {
  text-decoration: underline; }

.teaser-l,
.frame-teaser-l {
  position: relative;
  width: 48.5%;
  margin-left: 1%;
  float: left; }

.teaser-l .csc-header,
.frame-teaser-l .csc-header {
  position: absolute;
  bottom: 16px;
  left: 0;
  height: 50px;
  background-color: #000;
  opacity: 0.4;
  z-index: 1000;
  text-align: center;
  left: 0;
  right: 0; }

.teaser-l .csc-header a,
.frame-teaser-l .csc-header a {
  position: absolute;
  font-size: 16px;
  width: 100%;
  z-index: 10000;
  opacity: 1.0;
  color: #fff;
  left: 0;
  right: 0;
  padding: 16px;
  height: 50px;
  font-weight: normal !important; }

.teaser-s figure,
.teaser-l figure,
.frame-teaser-s figure,
.frame-teaser-l figure {
  width: 100% !important; }

.teaser-l figure,
.frame-teaser-l figure {
  margin-left: 0 !important;
  margin-bottom: 0 !important; }

.teaser-u,
.frame-teaser-u {
  width: 50%;
  float: left;
  position: relative;
  cursor: pointer; }

.teaser-u .csc-header a,
.frame-teaser-u .csc-header a {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  display: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 0px 20px 0px;
  text-align: center;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6); }

.teaser-u figure,
.frame-teaser-u figure {
  margin: 0 !important;
  padding: 0 !important; }

.teaser-u:hover .csc-header a,
.frame-teaser-u:hover .csc-header a {
  display: block; }

.teaser-u img,
.teaser-u .ce-gallery,
.frame-teaser-u img,
.frame-teaser-u .ce-gallery {
  width: 100% !important;
  height: auto !important; }

.footerlinks a {
  padding: 0 10px;
  border-right: 1px solid; }

.footerlinks a:last-of-type {
  border-right: none; }

.resetbutt,
.submitbutt {
  opacity: 0;
  visibility: hidden; }

.responsive-100 .ce-gallery,
.responsive-100 img,
.responsive-100 figure,
.responsive-100-borderless .ce-gallery,
.responsive-100-borderless img,
.responsive-100-borderless figure,
.frame-responsive-100 .ce-gallery,
.frame-responsive-100 img,
.frame-responsive-100 figure,
.frame-responsive-100-borderless .ce-gallery,
.frame-responsive-100-borderless img,
.frame-responsive-100-borderless figure,
.slider2 img,
.slider2 figure,
.slider2 .ce-gallery,
.responsive-50 .ce-gallery,
.responsive-50 img,
.responsive-50 figure,
.responsive-50-borderless .ce-gallery,
.responsive-50-borderless img,
.responsive-50-borderless figure,
.frame-responsive-50 .ce-gallery,
.frame-responsive-50 img,
.frame-responsive-50 figure,
.frame-responsive-50-borderless .ce-gallery,
.frame-responsive-50-borderless img,
.frame-responsive-50-borderless figure {
  width: 100% !important;
  height: auto !important;
  display: block; }

.responsive-50,
.frame-responsive-50 {
  width: 49% !important;
  display: inline-block; }

.responsive-50:last-of-type,
.frame-responsive-50:last-of-type {
  float: right; }

.responsive-50-borderless,
.frame-responsive-50-borderless {
  width: 50% !important;
  float: left; }

.responsive-100-borderless,
.frame-responsive-100-borderless {
  width: 100% !important;
  float: left; }

.responsive-100-borderless,
.responsive-50-borderless,
.responsive-100-borderless figure,
.responsive-50-borderless figure,
.frame-responsive-100-borderless,
.frame-responsive-50-borderless,
.frame-responsive-100-borderless figure,
.frame-responsive-50-borderless figure {
  padding: 0 !important;
  margin: 0 !important; }

.slider2 .csc-header a {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  display: block;
  background: #f9ec24;
  color: #000000;
  width: 50px;
  z-index: 5000;
  font-size: 14px;
  font-weight: normal; }

.slider2 .csc-header a:hover {
  color: #fff;
  background: #296aa2; }

/* the class name was already incorrect before the update */
#gallerie-angebotsspektrum ce-gallery {
  width: 100% !important;
  height: auto !important; }

.download-zip {
  font-size: 25px;
  color: #27ABDD; }

.download-zip:hover {
  text-decoration: underline !important; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
Media Query 1000px
++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (max-width: 1000px) {
  .dienstleistungen .csc-menu li {
    width: 33%; }
  .dienstleistungen .csc-menu li a {
    height: 80px;
    padding: 30px 0 !important; }
  #akkordeon .table-img-fest img {
    width: 200px !important;
    max-width: none !important; } }

/*++++++++++++++++++++++++++++++++++++++++++++++++
Media Query 900px
++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (max-width: 900px) {
  .teaser-s,
  .teaser-l,
  .frame-teaser-s,
  .frame-teaser-l {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%; }
  .aktuelles .text-aktuelles-li-wide .frame-default .ce-bodytext-with-gallery {
    width: 100%; }
  .aktuelles .text-aktuelles-li-wide .frame-default .ce-gallery-with-bodytext {
    width: 100% !important;
    padding-top: 4%; }
  header .header_pic .frame-default h1 {
    left: 35px;
    width: 95%;
    letter-spacing: 3px;
    font-size: 24px;
    line-height: 28px; }
  .ansprechpartner,
  .frame-ansprechpartner {
    width: 100%;
    margin-bottom: 20px; }
  #Language {
    overflow: auto;
    padding-bottom: 10px;
    background: #0071bc;
    position: static;
    /* �nderung Swen Bauer 24.01.2017 - Abstand nach oben vergr��ert. */
    margin-top: 19px; }
  header#header-bg {
    height: auto !important;
    min-height: 130px !important; }
  header#header-bg #Language {
    padding-top: 95px; }
  .table-white-training table,
  .frame-table-white-training table {
    min-width: 400px; } }

/*++++++++++++++++++++++++++++++++++++++++++++++++
Media Query 600px
++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (max-width: 600px) {
  .dienstleistungen .csc-menu li {
    width: 50%;
    padding: 2% 2% 0 0; }
  header .header_pic .frame-default h1 {
    top: 58%;
    left: 10px;
    width: 95%;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 16px; }
  .teaser-product .csc-header,
  .frame-teaser-product .csc-header {
    width: 99% !important; }
  #slick-slider {
    top: 35% !important; } }

/*++++++++++++++++++++++++++++++++++++++++++++++++
wrapper
++++++++++++++++++++++++++++++++++++++++++++++++*/
#wrapper {
  width: 100%;
  max-width: 1100px;
  height: 100%;
  min-height: 100%;
  height: auto !important;
  overflow: hidden !important;
  margin: 20px auto;
  background-color: #fff;
  font-size: 0.85em;
  position: relative; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
header
++++++++++++++++++++++++++++++++++++++++++++++++*/
header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  line-height: 0;
  background-color: #0071BC; }

header img {
  width: 100%;
  height: auto; }

header#header-bg {
  background: #0071BC;
  height: 170px;
  min-height: 170px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
header - meta
++++++++++++++++++++++++++++++++++++++++++++++++*/
#meta {
  position: absolute;
  top: 25px;
  right: 2.25%;
  width: 76%;
  /* 836 / 1100 */
  z-index: 10000; }

#meta ul {
  float: right; }

#meta ul li {
  float: left;
  margin-right: 20px;
  list-style-type: none; }

#meta ul li:nth-of-type(3) {
  position: relative;
  z-index: 1000; }

#meta ul li a {
  display: block;
  font-size: 0.8em;
  color: #000;
  text-decoration: none;
  padding-top: 6px; }

#meta ul li a:hover {
  text-decoration: underline; }

#meta input[type=text] {
  max-width: 120px;
  padding: 2px 0 1.75px 5px;
  margin-top: -4px;
  font-size: 0.8em; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
header - logo
++++++++++++++++++++++++++++++++++++++++++++++++*/
#logo {
  position: absolute;
  top: 23px;
  left: 1.090909090909091%;
  /* 12 / 1100 */
  width: 20.909090909090907%;
  /* 230 / 1100 */
  z-index: 10000; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
slick carousel
++++++++++++++++++++++++++++++++++++++++++++++++*/
#slick-slider {
  position: absolute;
  top: 40%;
  left: 1.090909090909091%;
  /* 12 / 1100 */
  width: 75%;
  margin-left: 30px;
  z-index: 1000; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
header - titel
++++++++++++++++++++++++++++++++++++++++++++++++*/
#header-titel {
  position: absolute;
  top: 45%;
  left: 10%;
  width: 80%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 3px;
  font-size: 28px;
  line-height: 28px;
  z-index: 10000; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
nav:not(.frame-default)
++++++++++++++++++++++++++++++++++++++++++++++++*/
nav:not(.frame-default) {
  clear: both;
  float: left;
  width: 100%;
  background-color: #0071BC; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
nav:not(.frame-default)i - desktop und mobil
++++++++++++++++++++++++++++++++++++++++++++++++*/
nav:not(.frame-default) ul {
  float: left;
  width: 100%; }

nav:not(.frame-default) ul li {
  float: left;
  margin-right: 0.23%;
  width: 14.05%;
  list-style-type: none;
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/menu_inaktiv.jpg"); }

nav:not(.frame-default) ul li a:last-of-type {
  margin-right: 0; }

nav:not(.frame-default) ul li a {
  display: block;
  width: 100%;
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 6px 0; }

nav:not(.frame-default) ul li a:hover {
  font-weight: bold;
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/menu_aktiv.jpg"); }

nav:not(.frame-default) .aktiv {
  font-weight: bold;
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/menu_aktiv.jpg"); }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content
++++++++++++++++++++++++++++++++++++++++++++++++*/
#content {
  clear: both;
  float: left;
  width: 100%;
  color: #fff;
  background: #0071BC; }

h2 {
  font-size: 1.2em; }

#breadcrumb h4 {
  font-size: 0.8em;
  padding: 1% 2px 3% 1%; }

#breadcrumb a {
  color: #ffffff;
  font-weight: normal; }

#breadcrumb a:hover {
  text-decoration: underline; }

a {
  text-decoration: none; }

.fett {
  font-weight: bold; }

.kursiv {
  font-style: italic; }

.zentriert {
  text-align: center; }

.unterstrich {
  text-decoration: underline;
  font-weight: bold; }

.unt {
  padding-bottom: 60px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - index.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec1bis3-index {
  clear: both;
  float: left;
  width: 100%;
  padding: 20px 0; }

#sec1bis3-index section {
  float: left; }

#sec1bis3-index h3 {
  padding-bottom: 10px; }

#sec1bis3-index ul li div {
  display: inline-block; }

#sec1bis3-index ul li a:hover {
  color: #fcee21;
  font-weight: bold;
  text-decoration: underline; }

#sec1-index {
  width: 50%;
  padding-left: 1%;
  padding-right: 2%;
  padding-top: 1%; }

#sec1-index h2 {
  padding-bottom: 10px; }

#sec2-index {
  width: 25%;
  padding-left: 4%;
  margin-top: 3%; }

#sec3-index {
  width: 25%;
  padding-left: 4%;
  margin-top: 3%; }

#sec2-index ul li,
#sec3-index ul li {
  list-style-type: none; }

#sec2-index ul li a,
#sec3-index ul li a {
  color: #fff;
  text-decoration: none; }

.myMarquee {
  width: 100%;
  height: 20px;
  overflow: hidden;
  position: relative;
  border: 1px solid #66a4cb;
  margin: 0 auto;
  animation: 20s;
  -o-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  margin: 2% 0% 2% 1%;
  width: 98%;
  background: #3a5996; }

.scroller {
  display: block;
  height: 20px;
  width: 200%;
  position: absolute;
  left: 0;
  top: 0;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-duration: 20s;
  -moz-animation-name: scroll;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-duration: 20s;
  -webkit-animation-name: scroll;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  -o-animation-duration: 20s;
  -o-animation-name: scroll;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 20s;
  animation-name: scroll; }

.scroller div {
  font-family: arial, serif;
  font-size: 11px;
  width: auto;
  line-height: 20px;
  float: left;
  color: #fff;
  font-weight: bold;
  padding: 0 10px; }

.scroller div a {
  color: #c00; }

@-moz-keyframes scroll {
  0% {
    left: 100%; }
  100% {
    left: -80%; } }

@-webkit-keyframes scroll {
  0% {
    left: 100%; }
  100% {
    left: -80%; } }

@-o-keyframes scroll {
  0% {
    left: 100%; }
  100% {
    left: -80%; } }

@keyframes scroll {
  0% {
    left: 100%; }
  100% {
    left: -80%; } }

.scroller:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
sec4 - index.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec4-index {
  clear: both;
  float: left;
  width: 100%; }

#sec4-index figure {
  width: 23.75%;
  margin-left: 1%; }

#sec4-index figure img {
  width: 100%;
  height: auto; }

#sec4-index figure figcaption {
  width: 100%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
sec5 - index.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec5-index {
  clear: both;
  float: left;
  width: 100%;
  padding-bottom: 30px; }

#sec5-index img {
  width: 100%;
  height: auto; }

#sec5-index figure {
  position: relative;
  width: 48.5%;
  margin-left: 1%; }

#sec5-index .hg {
  position: absolute;
  width: 100%;
  bottom: 19px;
  left: 0;
  height: 50px;
  background-color: #000;
  opacity: 0.4;
  z-index: 1000; }

#sec5-index .hg p {
  position: absolute;
  font-size: 1.2em;
  bottom: 16px;
  width: 100%;
  z-index: 10000;
  opacity: 1.0; }

figure {
  float: left;
  padding-bottom: 16px;
  /* �nderung Swen Bauer 24.01.2017 - Abstand nach Unten padding verkleinert. */
  padding-bottom: 16px; }

figure a {
  text-align: center;
  color: #fff;
  text-decoration: none; }

figure a:hover {
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - angebot.html - divs
++++++++++++++++++++++++++++++++++++++++++++++++*/
#kontakt-formular {
  float: left;
  width: 50%;
  padding-top: 30px; }

#kontakt-formular form div {
  margin-top: 5px; }

#kontakt-links {
  float: left;
  width: 25%;
  padding-top: 30px;
  padding-left: 20px; }

#kontakt-rechts {
  float: right;
  width: 25%;
  padding-top: 30px; }

#kontakt-links p a {
  color: #29abe2;
  text-decoration: none; }

#kontakt-rechts p {
  padding-left: 5px;
  padding-right: 12px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - angebot.html/kontakt.html - formular
++++++++++++++++++++++++++++++++++++++++++++++++*/
.kontakt {
  padding-bottom: 30px; }

form div label {
  display: block;
  width: 100%;
  padding: 3.35px 0; }

.input-hg {
  width: 96%;
  color: #000;
  font-weight: bold;
  background-color: #eee;
  border: none;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 0.95em;
  font-style: italic; }

.input-hg:hover {
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-style: normal; }

.btn_senden {
  width: 70%;
  margin-left: 13%;
  padding: 12px 0;
  color: #fff;
  font-weight: bold;
  background-color: #29ABE2;
  border: none;
  margin-top: 20px; }

.btn_senden:hover {
  background-color: #fcef32;
  color: #000; }

.tx-powermail input[type="submit"]:hover,
.tx-powermail input[type="reset"]:hover {
  background-color: #fcef32;
  color: #000; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - sitemap.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#content.farbverlauf {
  background-image: linear-gradient(#0071bc, #fcfefe);
  /* Farbverlauf */ }

#sitemap {
  float: left;
  width: 98%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 30px;
  color: #000;
  background-image: linear-gradient(#cae2f1, #f8fbfd);
  /* Farbverlauf */ }

#sitemap a {
  color: #003068; }

#sitemap a:hover {
  text-decoration: underline; }

#sitemap h2 {
  font-size: 1.4em; }

#sitemap h3,
#sitemap h4,
#sitemap h5 {
  font-size: 1.1em; }

#sec1-sitemap {
  float: left;
  width: 33.33333333333333%; }

#sec2und3-sitemap {
  float: right;
  width: 66.66666666666667%; }

#sec2-sitemap {
  float: left;
  width: 50%; }

#sec3-sitemap {
  float: right;
  width: 50%;
  margin-top: 35px; }

#sitemap ul li {
  list-style-type: none; }

#sitemap .li2-sitemap {
  margin-left: 20px; }

#sitemap .li3-sitemap {
  margin-left: 15px; }

#sitemap .einzug1-sitemap {
  padding-left: 12px; }

#sitemap .einzug2-sitemap {
  padding-left: 12px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - impressum.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec1bis2-imp {
  float: left;
  width: 100%;
  padding-bottom: 60px;
  padding-top: 40px; }

#sec1-imp {
  float: left;
  width: 25%; }

#sec1-imp h2 {
  font-size: 1.3em;
  padding-left: 5%; }

#sec2-imp {
  float: right;
  width: 74%;
  color: #000;
  background-color: #c9e1f1;
  margin-right: 1%;
  padding: 0 2%;
  background-image: linear-gradient(#c9e1f1, #fcfdfe);
  /* Farbverlauf */ }

#sec2-imp p {
  padding-right: 40px; }

#sec2-imp div {
  padding-top: 20px; }

#sec2-imp h2 {
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 40px; }

#sec2-imp div h3 {
  font-weight: bold;
  font-size: 1.2em; }

#sec2-imp div h5 {
  font-weight: bold;
  font-size: 1em; }

.rahmen-unten {
  border-bottom: solid 1px #000;
  padding-bottom: 60px; }

.daten {
  padding-bottom: 120px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - angebotsspektrum.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.angebotsspektrum {
  padding-bottom: 30px; }

.angebotsspektrum ul li {
  list-style-type: none; }

.angebotsspektrum ul li a {
  color: #fff;
  text-decoration: none; }

.angebotsspektrum img {
  width: 100%;
  height: auto; }

.angebotsspektrum h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

.sec2bis4-angebotsspektrum section {
  float: left; }

.sec2bis4-angebotsspektrum h3 {
  padding-bottom: 20px; }

#sec2-angebotsspektrum {
  width: 50%;
  padding-left: 1%;
  padding-right: 1%; }

#sec3-angebotsspektrum {
  width: 25%;
  padding-left: 4%; }

#sec4-angebotsspektrum {
  width: 25%;
  padding-left: 4%; }

#sec3-angebotsspektrum ul li div,
#sec4-angebotsspektrum ul li div {
  display: inline-block; }

#sec3-angebotsspektrum ul li a:hover,
#sec4-angebotsspektrum ul li a:hover {
  color: #ff0;
  font-weight: bold;
  text-decoration: underline; }

#bilder-angebotsspektrum {
  clear: left;
  float: right;
  width: 43.5%;
  margin-right: 2%;
  margin-top: 6%; }

#bilder-angebotsspektrum #bilder1bis5 {
  width: 100%; }

#bilder-angebotsspektrum #bilder1bis5 div {
  float: left; }

#bilder-angebotsspektrum #bilder1bis5 #bild1-angebotsspektrum,
#bilder-angebotsspektrum #bilder1bis5 #bild2-angebotsspektrum,
#bilder-angebotsspektrum #bilder1bis5 #bild3-angebotsspektrum,
#bilder-angebotsspektrum #bilder1bis5 #bild4-angebotsspektrum,
#bilder-angebotsspektrum #bilder1bis5 #bild5-angebotsspektrum {
  width: 20%; }

#bilder-angebotsspektrum img {
  background-color: #fff; }

#bilder-angebotsspektrum #bilder1bis5 img {
  border: solid 1px #000; }

#bilder-angebotsspektrum #bilder1bis5 #bild1-angebotsspektrum img,
#bilder-angebotsspektrum #bilder1bis5 #bild2-angebotsspektrum img,
#bilder-angebotsspektrum #bilder1bis5 #bild3-angebotsspektrum img,
#bilder-angebotsspektrum #bilder1bis5 #bild4-angebotsspektrum img {
  border-right: none; }

#gallerie-angebotsspektrum {
  clear: right;
  float: left;
  width: 100%;
  margin-top: 5%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - dienstleistungen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.dienstleistungen {
  padding-bottom: 30px; }

#sec2-dienstleistungen {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 1%; }

#sec2-dienstleistungen h2 {
  padding-left: 1%;
  padding-right: 2%;
  font-size: 1.35em;
  padding-bottom: 20px; }

#sec3-dienstleistungen {
  float: right;
  width: 49%;
  margin-right: 1%; }

#sec4-dienstleistungen {
  clear: both;
  float: left;
  width: 99%;
  margin-top: 4%;
  margin-left: 1%; }

#sec4-dienstleistungen .aussen,
#sec4-dienstleistungen .innen {
  width: 16.66666666666667%; }

#sec4-dienstleistungen .aussen {
  display: table;
  float: left; }

#sec4-dienstleistungen .innen {
  display: table-cell;
  vertical-align: middle;
  padding-right: 5%;
  padding-top: 5%; }

#sec4-dienstleistungen .innen > div {
  width: 100%;
  margin: 0 auto; }

#sec4-dienstleistungen a.dl-zentrierung {
  display: block;
  font-size: 1em;
  color: #000;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(#ffffff, #a6bad2);
  /* Farbverlauf */
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  height: 60px;
  line-height: 16px;
  padding-top: 22px; }

#sec4-dienstleistungen a.dl2-zentrierung {
  display: block;
  font-size: 1em;
  color: #000;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(#ffffff, #a6bad2);
  /* Farbverlauf */
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  height: 60px;
  line-height: 16px;
  padding-top: 14px; }

#sec4-dienstleistungen a:hover {
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content dienstleistungen - Unterseiten
++++++++++++++++++++++++++++++++++++++++++++++++*/
.dl {
  padding-bottom: 30px; }

.sec1-dl {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 2%; }

.sec1-dl h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

.sec2-dl {
  float: right;
  width: 49%;
  margin-right: 1%;
  line-height: 0; }

.dl img {
  width: 100%;
  height: auto; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content dienstleistungen - risikoanalyse.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1-risikoanalyse {
  clear: both;
  float: left;
  width: 100%; }

.bild2und3-risikoanalyse {
  float: left;
  width: 100%;
  line-height: 0; }

.bild2-risikoanalyse {
  clear: both;
  float: left;
  width: 50%; }

.bild3-risikoanalyse {
  float: right;
  width: 50%; }

/*content dienstleistungen - maschinensteuerung.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1-maschinensteuerung {
  float: left;
  width: 50%; }

.bild2und3-maschinensteuerung {
  float: right;
  width: 50%; }

.bild2-maschinensteuerung {
  float: left;
  width: 100%; }

.bild3-maschinensteuerung {
  float: left;
  width: 100%; }

/*content dienstleistungen - technischersupport.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1und2-technischersupport {
  width: 100%; }

.bild1-technischersupport {
  float: left;
  width: 50%; }

.bild2-technischersupport {
  float: right;
  width: 50%; }

.bild3-technischersupport {
  clear: both;
  width: 100%; }

/*content dienstleistungen - sicherheitspruefungen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1und2-sicherheitspruefungen {
  width: 100%; }

.bild1-sicherheitspruefungen {
  float: left;
  width: 50%; }

.bild2-sicherheitspruefungen {
  float: right;
  width: 50%; }

.bild3-sicherheitspruefungen {
  clear: both;
  width: 100%; }

/*content dienstleistungen - anlagenmodernisierung.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1und2-anlagenmodernisierung {
  float: left;
  width: 50%;
  line-height: 0; }

.bild1-anlagenmodernisierung {
  float: left;
  width: 100%; }

.bild2-anlagenmodernisierung {
  clear: left;
  float: left;
  width: 100%; }

.bild3-anlagenmodernisierung {
  float: right;
  width: 50%; }

/*content dienstleistungen - ersatzteilversorgung.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1-ersatzteilversorgung {
  float: left;
  width: 100%;
  line-height: 0; }

.bild2-ersatzteilversorgung {
  clear: left;
  width: 100%; }

/*content dienstleistungen - schulungen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1-schulungen {
  float: left;
  width: 100%;
  line-height: 0; }

.bild2-schulungen {
  clear: left;
  width: 100%; }

/*content dienstleistungen - emvservice.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.bild1und2-emvservice {
  float: right;
  width: 100%;
  line-height: 0; }

.bild1-emvservice {
  float: left;
  width: 50%; }

.bild2-emvservice {
  float: right;
  width: 50%; }

.bild3-emvservice {
  clear: both;
  width: 100%; }

/*content dienstleistungen - datenblaetter.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.unterstrichen {
  text-decoration: underline; }

#ub-datenblaetter {
  padding-left: 1%;
  padding-right: 1%; }

#ub-datenblaetter p {
  width: 45%; }

#inhalt-datenblaetter {
  float: left;
  width: 98%;
  background-color: #fff;
  color: #000;
  margin-top: 60px;
  margin-left: 1%;
  margin-right: 1%;
  overflow: auto;
  line-height: normal !important; }

#inhalt-datenblaetter h3 {
  color: #000;
  padding: 10px;
  font-size: 18px; }

#inhalt-datenblaetter table {
  float: left;
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-bottom: 30px;
  border-collapse: collapse;
  font-size: 13px !important; }

#inhalt-datenblaetter table tr th {
  background-color: #c5c5c5; }

#inhalt-datenblaetter table tr th,
#inhalt-datenblaetter table tr td {
  border: solid 1px #000;
  padding: 3px; }

#inhalt-datenblaetter table tr th img,
#inhalt-datenblaetter table tr td img {
  min-height: 26px;
  max-height: 26px;
  min-width: 26px;
  max-width: 26px; }

#inhalt-datenblaetter a {
  color: #000;
  font-weight: bold;
  text-decoration: underline; }

#inhalt-datenblaetter a:hover {
  text-decoration: none; }

/*content dienstleistungen - sistema.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.sec1-dl div {
  margin-top: 30px; }

.bild1-sistema {
  float: left;
  width: 50%; }

.bild2-sistema {
  float: right;
  width: 50%; }

#bereich1-sistema {
  float: left;
  width: 100%; }

#bereich2-sistema {
  clear: left;
  float: left;
  width: 100%; }

#bereich3-sistema {
  clear: left;
  float: left;
  width: 100%; }

.bild3bis5-sistema {
  float: left;
  width: 30%; }

.text-sistema {
  float: right;
  width: 64%;
  padding-right: 1%; }

.text-sistema a {
  display: block;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding-bottom: 10px; }

.text-sistema a:hover {
  color: #fcee21;
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - branchen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.branchen {
  padding-bottom: 30px; }

.branchen h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

#sec1bis3-branchen section {
  float: left; }

#sec1-branchen {
  width: 25%;
  padding-right: 1%;
  padding-left: 1%; }

#sec2-branchen {
  width: 50%; }

#sec3-branchen {
  width: 25%;
  padding-left: 1%;
  padding-right: 1%; }

#sec3-branchen li {
  width: 100%;
  margin: 0px auto;
  margin-bottom: 10px;
  list-style-type: none; }

#sec3-branchen li a {
  text-align: center;
  text-decoration: none;
  color: #fff;
  margin: 0px auto;
  padding: 10px 0px 10px 0px;
  display: block;
  background: #296aa2;
  border: 1px solid #ffffff; }

#sec3-branchen li a:hover {
  background: #64aadc; }

a.branche-more {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  display: block;
  background: #f9ec24;
  color: #000000;
  width: 50px;
  z-index: 5000; }

a.branche-more:hover {
  background: #296aa2; }

#sec2-branchen div {
  position: relative; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - branchen - Unterseiten
++++++++++++++++++++++++++++++++++++++++++++++++*/
.branchen {
  padding-bottom: 30px; }

.branchen img {
  width: 100%;
  height: auto; }

.sec1-br {
  float: left;
  width: 47%;
  margin-right: 2%;
  margin-left: 1%; }

.sec2-br {
  float: right;
  width: 47%;
  margin-right: 1%;
  margin-left: 2%; }

.bild2und3-br {
  clear: left;
  width: 100%; }

.bild1-br {
  float: left;
  width: 100%;
  line-height: 0; }

.bild2-br {
  float: left;
  width: 49%;
  margin-top: 15px; }

.bild3-br {
  float: right;
  width: 49%;
  margin-top: 15px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - aktuelles.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.aktuelles {
  padding-bottom: 30px; }

.aktuelles-inhalt {
  float: left;
  margin-top: 30px;
  width: 100%; }

.aktuelles-inhalt h2 {
  padding-top: 10px;
  font-size: 1.35em; }

.aktuelles-inhalt img {
  width: 100%;
  height: auto; }

.aktuelles-inhalt:nth-of-type(1) {
  margin-top: 1%; }

.aktuelles-inhalt .text-aktuelles-li {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 2%; }

.aktuelles-inhalt .text-aktuelles-li-wide {
  float: left;
  width: 98%;
  padding-left: 1%;
  padding-right: 1%; }

.aktuelles-inhalt .bilder-aktuelles-re {
  float: right;
  width: 50%;
  padding-right: 1%; }

.aktuelles-inhalt .bilder-aktuelles-re img {
  width: auto;
  height: auto;
  max-width: 100%; }

.text-aktuelles-li h4 {
  font-size: 0.8em;
  padding: 0 1% 3% 0; }

.bilder-aktuelles-re a {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  padding: 25px 0;
  background-image: linear-gradient(#ffffff, #a6bad2);
  /* Farbverlauf */
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: 2px 2px 5px #333; }

.bilder-aktuelles-re a:hover {
  text-decoration: underline;
  box-shadow: 1px 1px 3px #333; }

.bilder-aktuelles-re .b1 {
  float: left;
  width: 30%;
  height: 100%;
  margin-right: 5%; }

.bilder-aktuelles-re .b1:last-of-type {
  margin-right: 0; }

.bilder-aktuelles-re .b2 {
  float: left;
  width: 47.5%;
  height: 100%;
  margin-right: 5%; }

.bilder-aktuelles-re .b2:last-of-type {
  margin-right: 0; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - messen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.me {
  padding-bottom: 30px; }

.me img {
  width: 100%;
  height: auto; }

#sec1-messen {
  float: left;
  width: 25%;
  padding-left: 1%; }

#sec2-messen {
  float: right;
  width: 74%;
  margin-right: 1%; }

.messen {
  float: left;
  width: 100%;
  padding-bottom: 30px; }

.messen-bilder {
  float: left;
  width: 35%; }

.messen-bilder img {
  width: auto;
  height: auto;
  max-width: 100%; }

.messen-text {
  float: right;
  width: 65%;
  padding-left: 2%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - unternehmen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.unternehmen {
  padding-bottom: 30px; }

.unternehmen img {
  width: 100%;
  height: auto; }

.unternehmen h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

#sec2-unternehmen {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 1%; }

#sec2-unternehmen p a {
  color: #fff; }

#sec2-unternehmen p a:hover {
  color: #fcee21;
  font-weight: bold;
  text-decoration: underline; }

#sec3-unternehmen {
  float: right;
  width: 49%;
  margin-right: 1%;
  line-height: 0; }

#sec3-unternehmen .block {
  width: 50%;
  float: left;
  position: relative;
  cursor: pointer; }

#sec3-unternehmen .block p {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 0px 20px 0px;
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/bg-block-u.png") repeat;
  text-align: center;
  display: none;
  color: #ffffff; }

#sec3-unternehmen .block:hover p {
  display: block; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Unternehmen - Unterseiten
++++++++++++++++++++++++++++++++++++++++++++++++*/
.unt img {
  width: 100%;
  height: auto; }

.unt h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

.sec2-unt {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 2%; }

.sec3-unt {
  float: right;
  width: 49%;
  margin-right: 1%; }

.sec3-unt .kundenvorteile {
  width: 100%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - karriere.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec1bis2-karriere {
  float: left;
  width: 100%;
  padding-bottom: 30px; }

#sec1bis2-karriere h2 {
  font-size: 1.35em;
  padding-bottom: 20px; }

#sec2-karriere {
  float: left;
  width: 48%;
  margin-left: 1%;
  margin-right: 1%; }

#sec3-karriere {
  float: right;
  width: 49%;
  margin-right: 1%; }

#sec3-karriere p {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 1em; }

#sec3-karriere p a {
  font-size: 1.2em;
  font-weight: bold;
  color: #fff; }

#sec3-karriere p a:hover {
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Karriere - stellenangebote.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
.sec4-karriere {
  clear: both;
  float: left;
  width: 98%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  background-color: #c9e1f1;
  color: #000;
  font-size: 0.95em; }

.sec4-karriere h2 {
  font-size: 1.2em; }

.sec4-karriere ul li {
  list-style-type: square;
  margin-left: 16px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - kontakt.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec1-kontakt {
  clear: both;
  float: left;
  width: 100%;
  padding-bottom: 30px; }

#sec1-kontakt h2 {
  font-size: 1.35em;
  padding-bottom: 1%; }

#sec1-kontakt #links-links-kontakt {
  width: 100%;
  padding-left: 1%;
  padding-right: 1%; }

#sec1-kontakt #links-links-kontakt .aussen,
#sec1-kontakt #links-links-kontakt .innen {
  width: 31%;
  height: 100%; }

#sec1-kontakt #links-links-kontakt .aussen {
  display: table;
  float: left;
  margin-right: 3.5%; }

#sec1-kontakt #links-links-kontakt .aussen:last-of-type {
  margin-right: 0; }

#sec1-kontakt #links-links-kontakt .innen {
  display: table-cell; }

#sec1-kontakt #links-links-kontakt .innen a {
  display: block;
  color: #000;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 1.2em;
  text-decoration: none;
  padding: 18px 0;
  background-image: linear-gradient(#ffffff, #a6bad2);
  /* Farbverlauf */
  -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
  box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86); }

#sec1-kontakt #links-links-kontakt .innen a:hover {
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Formular - kontakt.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec2-kontakt form {
  clear: both;
  float: left;
  width: 50%;
  padding-left: 1%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Kontakt - ansprechpartner.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#ansprechpartner {
  float: left;
  width: 99%; }

#titel-ansprechpartner {
  width: 100%;
  padding-left: 1%;
  padding-bottom: 20px;
  color: #fff;
  font-size: 1.1em; }

#ansprechpartner ul {
  float: left;
  width: 49%;
  color: #000;
  margin-bottom: 40px;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #c9e1f1; }

#ansprechpartner ul li {
  list-style-type: none; }

#ansprechpartner ul li {
  padding-left: 10px;
  padding-bottom: 8px; }

#ansprechpartner ul li:nth-of-type(1) {
  float: right;
  padding: 3% 7% 3% 2%; }

#ansprechpartner ul li:nth-of-type(2) {
  font-weight: bold;
  padding: 15px 10px; }

#ansprechpartner ul li:nth-of-type(3) {
  padding-top: 20px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Kontakt - vertretungen.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#vertretungen-ueberschrift {
  float: left;
  width: 25%;
  padding-left: 1%; }

#vertretungen {
  float: right;
  width: 74%;
  background-color: #c9e1f1;
  color: #000;
  margin-right: 1%;
  padding: 1%; }

#vertretungen h1 {
  font-size: 1.2em;
  padding-bottom: 20px; }

#vertretungen h2 {
  font-size: 1.2em;
  padding-top: 30px; }

#vertretungen h3 {
  font-size: 0.95em;
  padding-top: 12px;
  padding-bottom: 15px; }

#vertretungen .v {
  padding-bottom: 40px; }

#vertretungen .v.linie {
  border-bottom: solid 1px #000; }

#vertretungen .v.gepunktete-linie {
  border-top: dotted 1px #000; }

#vertretungen .vertretungen-links {
  float: left;
  width: 50%; }

#vertretungen .vertretungen-right {
  float: right;
  width: 50%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Kontakt - anfahrt.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#anfahrt {
  width: 100%; }

#titel-anfahrt {
  float: left;
  width: 25%;
  padding-left: 1%;
  font-size: 1em; }

#google-maps {
  float: right;
  width: 100%;
  color: #000; }

#google-maps iframe {
  width: 75%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Rufnummern - kontakt.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#sec3-kontakt {
  float: right;
  width: 49%;
  margin-right: 1%;
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 10px;
  padding-bottom: 2.5px;
  background-color: #c9e1f1;
  color: #000; }

#sec3-kontakt table tr td {
  padding-bottom: 5px; }

#sec3-kontakt table tr th {
  padding-bottom: 5px; }

#sec3-kontakt table tr td:nth-of-type(even),
#sec3-kontakt table tr th:nth-of-type(even) {
  text-align: center; }

#sec3-kontakt table tr th:nth-of-type(odd) {
  text-align: left; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - index - standardsensoren.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#gallerie-standardsensoren {
  float: left;
  width: 100%; }

#gallerie-standardsensoren #slider:not(.nivoSlider) img {
  width: 100%;
  height: 370px;
  max-height: 370px; }

#ub-standardsensoren {
  clear: both;
  float: left;
  width: 100%;
  padding-left: 1%; }

#sec2bis3-standardsensoren {
  clear: both;
  float: left;
  width: 100%; }

#sec2-standardsensoren {
  float: left;
  width: 50%;
  padding-left: 1%;
  padding-right: 2%; }

#sec3-standardsensoren {
  float: right;
  width: 50%; }

#sec3-standardsensoren ul li {
  list-style-type: square; }

#sec3-standardsensoren ul li a {
  display: block;
  color: #fff;
  padding-bottom: 10px; }

#sec3-standardsensoren ul li a:hover {
  color: #fcee21;
  font-weight: bold;
  text-decoration: underline; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - (mainCode für akkordeon)
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .accordeon-header {
  color: #fff;
  font-size: 1em;
  cursor: pointer; }

#akkordeon .accordeon-item--open .accordeon-header {
  background: #2666be; }

#akkordeon .accordion-content > .frame-default {
  clear: both;
  overflow: auto; }

#akkordeon .accordion-content-wrapper {
  padding: 30px 0; }

#akkordeon .accordion-content-wrapper:before,
#akkordeon .accordion-content-wrapper:after {
  content: "";
  display: block;
  clear: both; }

#akkordeon .ce-textpic .ce-gallery {
  max-width: 100% !important; }

#akkordeon img {
  max-width: 100%; }

#akkordeon {
  clear: both;
  max-width: 1080px;
  color: #7f7f7f;
  background-color: #fff;
  border: solid 1px #fff;
  margin: 0 auto; }

#akkordeon .accordeon-header {
  display: block;
  background-color: #c0ccda;
  color: #fff;
  font-size: 1em;
  padding: 8px 35px;
  border-bottom: solid 1px #fff;
  cursor: pointer;
  vertical-align: inherit;
  line-height: inherit; }

#akkordeon h4 {
  color: #7f7f7f; }

#akkordeon ul {
  margin: 5px; }

#akkordeon p {
  padding-top: 15px;
  line-height: 18px; }

#akkordeon ul li {
  list-style-type: square;
  margin-left: 10px;
  line-height: 18px; }

#akkordeon div.links-acc,
#akkordeon div.frame-links-acc {
  float: left;
  width: 50%;
  padding-left: 35px;
  padding-right: 17.5px;
  color: #7f7f7f;
  background-color: #fff;
  overflow: hidden; }

#akkordeon div.links-acc img,
#akkordeon div.frame-links-acc img {
  width: auto;
  height: auto;
  max-width: 100%; }

#akkordeon div.rechts-acc,
#akkordeon div.frame-rechts-acc {
  float: right;
  width: 50%;
  padding-left: 17.5px;
  padding-right: 35px;
  color: #7f7f7f;
  background-color: #fff; }

#akkordeon div.rechts-acc img,
#akkordeon div.frame-rechts-acc img {
  width: auto;
  height: auto;
  max-width: 100%; }

#akkordeon .image {
  max-width: 100%;
  display: block; }

#akkordeon div.links-acc30,
#akkordeon div.frame-links-acc30 {
  float: left;
  width: 30%;
  padding-left: 35px;
  padding-right: 17.5px;
  color: #7f7f7f;
  background-color: #fff;
  clear: both; }

#akkordeon div.links-acc30 img,
#akkordeon div.frame-links-acc30 img {
  width: auto; }

#akkordeon div.rechts-acc70,
#akkordeon div.frame-rechts-acc70 {
  float: right;
  width: 70%;
  padding-left: 17.5px;
  padding-right: 35px;
  color: #7f7f7f;
  background-color: #fff; }

#akkordeon div.rechts-acc70 img,
#akkordeon div.frame-rechts-acc70 img {
  max-width: 100%;
  width: auto; }

#akkordeon .ui-accordion-header-active {
  background-color: #2666be; }

#btn-acc {
  width: 100%; }

#btn-acc a {
  display: block;
  width: 35%;
  margin-left: 32.5%;
  padding: 12px 0;
  color: #fff;
  font-weight: bold;
  background-color: #29ABE2;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  text-align: center; }

#btn-acc a:hover {
  background-color: #fcef32;
  color: #000; }

/* innenabstand */
#akkordeon .innenabstand {
  padding: 30px 0; }

/* Links */
#akkordeon a {
  color: #000;
  text-decoration: underline; }

#akkordeon a:hover {
  font-weight: bold; }

/* Abs�tze */
#akkordeon div.absatz-acc {
  padding-left: 35px;
  clear: both; }

#akkordeon div.absatz-acc img {
  width: auto; }

/* absatz-blau */
#akkordeon .absatz-blau,
#akkordeon .frame-absatz-blau {
  clear: both;
  text-align: center;
  color: #296ac0;
  padding-top: 20px; }

#akkordeon .blau-dreispaltig {
  padding-top: 0;
  line-height: 0; }

#akkordeon .blau-dreispaltig .dreispaltig {
  float: left;
  text-align: center;
  list-style-type: none;
  color: #296ac0;
  width: 33.33333333333333%; }

/* Tabellen */
#akkordeon table {
  border-collapse: collapse;
  width: 100%; }

#akkordeon table tr {
  width: 100%; }

#akkordeon table tr th {
  font-size: 1.2em;
  padding-bottom: 5px;
  border: solid 1px #000;
  padding: 5px 5px 5px 5px;
  background-position: center center;
  min-width: 40px; }

#akkordeon table tr td {
  padding: 5px 5px 5px 5px;
  vertical-align: top;
  text-align: center;
  border: solid 1px #000; }

#akkordeon table img {
  padding-top: 0px;
  width: 26px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - kodierleisten.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .bild1-kodierleisten {
  width: 50%;
  margin-left: 15%; }

#akkordeon .bild2-kodierleisten {
  width: 30%;
  margin-left: 15%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
titel lv.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
header #lv-titel {
  position: absolute;
  top: 62%;
  left: 35px;
  width: 80%;
  color: #fff;
  font-weight: bold;
  text-align: left;
  letter-spacing: 2px;
  font-size: 28px;
  line-height: 28px;
  z-index: 10000; }

#lv-ueberschrift {
  float: left;
  width: 25%;
  padding-left: 1%; }

#lv-uebersicht {
  float: right;
  width: 75%; }

#lv-uebersicht ul {
  float: left;
  width: 100%;
  margin-bottom: 1%; }

#lv-uebersicht ul li {
  float: left;
  width: 33.33333333333333%;
  list-style-type: none;
  padding-right: 1%; }

#lv-uebersicht ul li .lv-bild {
  background-color: #1d7fc1;
  width: 100%; }

#lv-uebersicht .lv-desc {
  background-color: #1d7fc1;
  width: 100%;
  line-height: 20px;
  z-index: 5000;
  padding: 2% 0% 2% 0%;
  clear: both; }

#lv-uebersicht ul li .lv-desc ul {
  clear: both; }

#lv-uebersicht ul li .lv-desc ul li {
  clear: both !important;
  width: 90%;
  list-style-type: square;
  padding-right: 0%;
  margin-left: 30px;
  font-size: 12px; }

#lv-uebersicht ul li .lv-desc p {
  width: 100%;
  margin-left: 15px;
  font-size: 12px; }

#lv-uebersicht ul li .lv-weiter {
  min-height: 18px;
  width: 100%;
  background-image: linear-gradient(#3399cc, #336699);
  /* Farbverlauf */ }

#lv-uebersicht ul li .lv-textundbild-weiter {
  float: right;
  width: 35%; }

#lv-uebersicht ul li a.lv-text-weiter {
  float: left;
  color: #fff;
  width: 20px; }

#lv-uebersicht ul li .lv-bild-weiter {
  float: right;
  width: 30px;
  height: 18px;
  display: block;
  cursor: pointer;
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/pfeil1.jpg"); }

#lv-uebersicht ul li .lv-bild-weiter:hover {
  background-image: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/pfeil2.jpg"); }

#lv-uebersicht ul li .lv-bild-weiter b {
  display: none; }

#lv-uebersicht ul li .lv-weiter .neu {
  width: 16%;
  margin-left: 42%;
  font-size: 0.85em;
  color: red;
  text-align: center;
  background-color: #fff;
  padding: 2px 0;
  box-shadow: 0 0 10px #fff, 0 0 60px #fff, 0 0 150px #fff; }

#lv-uebersicht ul li .lv-link {
  clear: left;
  background-color: #005187;
  width: 100%; }

#lv-uebersicht ul li .lv-link a {
  display: block;
  color: #fff;
  text-align: center;
  font-size: 1.65em;
  padding: 14px 0; }

#lv-uebersicht ul li .lv-link a span {
  font-size: 0.6em; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - Durchhangsensoren - systemgsd.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon div.links-acc .gsd1,
#akkordeon div.frame-links-acc .gsd1 {
  width: 85%;
  height: auto; }

#akkordeon div.rechts-acc .gsd2,
#akkordeon div.frame-rechts-acc .gsd2 {
  width: 70%;
  height: auto;
  float: right;
  margin-top: -40px;
  margin-right: 30px; }

/* tabelle1 */
#akkordeon div.tabelle1-systemgsd table {
  clear: both;
  width: 70%;
  margin: 0 15%;
  text-align: center;
  border: solid 4px #dfdfdf;
  margin-top: 20px; }

#akkordeon div.tabelle1-systemgsd table tr th,
#akkordeon div.tabelle1-systemgsd table tr td {
  width: 25%; }

#akkordeon div.tabelle1-systemgsd table tr:nth-of-type(even) {
  background-color: #dfdfdf; }

/* tabelle2 */
#akkordeon div.tabelle2-systemgsd table {
  width: 80%;
  margin-right: 20%; }

#akkordeon div.tabelle2-systemgsd table tr td {
  width: 49%;
  padding-right: 1%;
  vertical-align: top; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - Durchhangsensoren - systemccd.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .ccd1 {
  float: right;
  width: 20%;
  margin-right: 55px; }

#akkordeon .ccd2 {
  width: 40%;
  margin-left: 15%; }

#akkordeon .ccd-anwendungsgebiete {
  clear: right; }

/* tabelle1 */
#akkordeon div.tabelle1-systemccd table {
  width: 80%;
  margin-right: 20%; }

#akkordeon div.tabelle1-systemccd table tr td {
  width: 49%;
  padding-right: 1%;
  vertical-align: top; }

/* tabelle2 */
#akkordeon div.tabelle2-systemccd table {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  border: solid 4px #dfdfdf; }

#akkordeon div.tabelle2-systemccd table tr th,
#akkordeon div.tabelle2-systemccd table tr td {
  width: 20%; }

#akkordeon div.tabelle2-systemccd table tr:nth-of-type(even) {
  background-color: #dfdfdf; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - Infrarotlichtschranken - multifunktionslichtschranke.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .liste-mfl ul {
  margin-left: 24px; }

#akkordeon .liste-mfl ul li {
  float: left;
  list-style-type: none;
  width: 25%; }

#akkordeon .liste-mfl ul img {
  width: 48%; }

#akkordeon div.tabelle-multifunktionslichtschranke table {
  width: 40%;
  margin-left: 35px; }

#akkordeon div.tabelle-multifunktionslichtschranke table tr td {
  width: 20%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - Standardsensoren - Infrarotlichtschranken - reflexlichtschranken.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .gr1 {
  padding-left: 8%; }

#akkordeon .gr2 {
  width: 90%;
  margin: 0 auto; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - einstrahligesicherheitslichtschranke.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon div.li-sicherheitsschranke {
  float: left;
  width: 60%; }

#akkordeon div.bilder-sicherheitslichtschranke img {
  width: auto;
  height: auto; }

#akkordeon div.absatz-sicherheitslichtschranke {
  clear: both;
  float: left;
  width: 100%;
  padding-right: 2%;
  padding-top: 20px; }

#akkordeon .tabelle-sicherheitslichtschranke table tr td {
  padding-right: 10px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - ulvtgitter.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .links-acc .anwendungsgebiete-ulvtgitter,
#akkordeon .frame-links-acc .anwendungsgebiete-ulvtgitter {
  float: left;
  width: 60%; }

#akkordeon .links-acc .bild1-ulvtgitter,
#akkordeon .frame-links-acc .bild1-ulvtgitter {
  float: right;
  width: 40%; }

#akkordeon .links-acc .bild1-ulvtgitter img,
#akkordeon .frame-links-acc .bild1-ulvtgitter img {
  width: 100%;
  height: auto; }

#akkordeon .rechts-acc .bild2-ulvtgitter,
#akkordeon .frame-rechts-acc .bild2-ulvtgitter {
  width: 100%;
  padding-top: 0;
  text-align: center; }

#akkordeon .rechts-acc .bild2-ulvtgitter img,
#akkordeon .frame-rechts-acc .bild2-ulvtgitter img {
  width: 60%; }

/* dreispaltiges Layout - ulvtgitter.html */
#akkordeon .dreispaltig-acc,
#akkordeon .frame-dreispaltig-acc {
  width: 100%;
  padding-left: 35px;
  padding-right: 35px; }

#akkordeon .dreispaltig-spalte1,
#akkordeon .frame-dreispaltig-spalte1 {
  float: left;
  width: 70%;
  background-color: #fff;
  padding-right: 16px; }

#akkordeon .dreispaltig-spalte2und3,
#akkordeon .frame-dreispaltig-spalte2und3 {
  float: right;
  width: 30%;
  background-color: #fff;
  font-size: 0.85em;
  color: #000; }

#akkordeon .dreispaltig-spalte2und3 .dreispaltig-spalte2,
#akkordeon .frame-dreispaltig-spalte2und3 .frame-dreispaltig-spalte2 {
  float: left;
  width: 40%;
  margin-left: 5%; }

#akkordeon .dreispaltig-spalte2und3 .dreispaltig-spalte3,
#akkordeon .frame-dreispaltig-spalte2und3 .frame-dreispaltig-spalte3 {
  float: right;
  width: 40%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - ulvt500.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .links-acc .bild-ulvt500,
#akkordeon .frame-links-acc .bild-ulvt500 {
  text-align: center;
  line-height: 0;
  width: 80%;
  padding-left: 15%; }

#akkordeon .tabelle-ulvt500 table {
  margin-bottom: 20px; }

#akkordeon .tabelle-ulvt500 table tr td {
  padding-right: 10px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
content - eu2k500.html
++++++++++++++++++++++++++++++++++++++++++++++++*/
#akkordeon .links-acc .anwendungsgebiete-eu2k500,
#akkordeon .frame-links-acc .anwendungsgebiete-eu2k500 {
  float: left;
  width: 60%; }

#akkordeon .links-acc .bild1-eu2k500,
#akkordeon .frame-links-acc .bild1-eu2k500 {
  float: right;
  width: 30%;
  text-align: right;
  padding-top: 0; }

#akkordeon .links-acc .bild1-eu2k500 img,
#akkordeon .frame-links-acc .bild1-eu2k500 img {
  width: 100%;
  height: auto; }

#akkordeon .links-acc .bild2-eu2k500,
#akkordeon .frame-links-acc .bild2-eu2k500 {
  clear: both;
  width: 50%;
  float: right;
  padding-top: 10px; }

#akkordeon .rechts-acc .bild3-eu2k500,
#akkordeon .frame-rechts-acc .bild3-eu2k500 {
  width: 100%;
  text-align: center;
  padding-top: 0; }

#akkordeon .rechts-acc .bild3-eu2k500 img,
#akkordeon .frame-rechts-acc .bild3-eu2k500 img {
  width: 40%;
  height: auto; }

#akkordeon .tabelle-eu2k500 table tr td {
  padding-right: 10px; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
footer
++++++++++++++++++++++++++++++++++++++++++++++++*/
footer {
  clear: both;
  float: left;
  width: 100%;
  font-size: 1em;
  color: #003068;
  background-color: #eee; }

#footer {
  padding: 30px 20px 0 20px; }

#borderunten {
  width: 100%;
  border-bottom: dotted 1px #000;
  padding-bottom: 20px;
  text-align: center; }

.footerlinks {
  padding-top: 15px; }

.footerlinks a {
  color: #003068; }

.footerlinks a:hover {
  text-decoration: underline; }

#footerinhalt {
  width: 100%;
  padding-top: 20px; }

#footerinhalt ul {
  float: left;
  width: 11.375%;
  padding-bottom: 30px; }

#footerinhalt ul:nth-of-type(2) li:nth-of-type(9), #footerinhalt ul:nth-of-type(2) li:nth-of-type(10), #footerinhalt ul:nth-of-type(2) li:nth-of-type(11), #footerinhalt ul:nth-of-type(2) li:nth-of-type(12) {
  display: none; }

#footerinhalt ul:nth-of-type(3) li:last-of-type {
  display: none; }

#body_en #footerinhalt ul, #body_fr #footerinhalt ul {
  margin-left: 2% !important; }

#body_en #footerinhalt ul:nth-of-type(2), #body_fr #footerinhalt ul:nth-of-type(2) {
  width: 12.5%; }

#body_en #footerinhalt ul:nth-of-type(8), #body_fr #footerinhalt ul:nth-of-type(8) {
  width: 8.5%; }

#footerinhalt ul:nth-of-type(1) li:nth-of-type(1) {
  margin-left: 2px; }

#footerinhalt ul li {
  list-style-type: square; }

#footerinhalt ul li:nth-of-type(1) {
  list-style-type: none;
  margin-bottom: 18px;
  margin-left: -12px; }

/*#footerinhalt ul li:first-of-child {*/
/*	margin-left: 12px;*/
/*}*/
#footerinhalt ul:nth-of-type(1) li {
  list-style-type: none; }

#footerinhalt ul li a {
  display: block;
  color: #003068;
  font-size: 0.9em; }

#footerinhalt ul li a:hover {
  text-decoration: underline; }

#footerinhalt ul:nth-of-type(2) {
  width: 13.5%;
  margin-left: 2%; }

#footerinhalt ul:nth-of-type(3) {
  margin-left: 3.65%; }

#footerinhalt ul:nth-of-type(4) {
  margin-left: 3.975%; }

#footerinhalt ul:nth-of-type(5) {
  width: 8.5%;
  margin-left: 1%; }

#footerinhalt ul:nth-of-type(7) {
  width: 9%;
  margin-left: 1.5%; }

#footerinhalt ul:nth-of-type(8) {
  width: 10.375%; }

header .tx-kesearch-pi1 {
  width: 120px !important; }

/*++++ Sven  ++++*/
#mobilmenu {
  display: none; }

.elastic-video,
.frame-elastic-video {
  position: relative;
  padding-bottom: 55%;
  padding-top: 15px;
  height: 0;
  overflow: hidden; }

.elastic-video iframe,
.frame-elastic-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*++++++++++++++++++++++++++++++++++++++++++++++++
Media Query 900px
++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (max-width: 900px) {
  .scroller {
    -moz-animation-duration: 15s;
    -webkit-animation-duration: 15s;
    -o-animation-duration: 15s;
    animation-duration: 15s; }
  #wrapper {
    margin-top: 0px; }
  #content {
    padding-top: 20px; }
  #mobilmenu {
    display: block;
    width: 100%;
    text-align: center;
    background-color: #ffff03; }
  nav:not(.frame-default) ul {
    width: 100%;
    margin-bottom: 4%;
    display: none; }
  nav:not(.frame-default) ul li {
    list-style-type: none;
    background-color: #ffff03;
    background-image: none;
    width: 100%;
    margin-top: 3px; }
  nav:not(.frame-default) ul li a:last-of-type {
    margin-right: 0; }
  nav:not(.frame-default) ul li a {
    display: block;
    color: #000;
    text-decoration: none;
    text-align: center;
    width: 100%; }
  nav:not(.frame-default) ul li a:hover {
    font-weight: bold;
    background-image: none;
    background-color: #ffffff; }
  nav:not(.frame-default) .aktiv {
    font-weight: bold;
    background-image: none;
    background-color: #ffffff; }
  .myMarquee {
    width: 98%; }
  #sec1-index {
    width: 100%; }
  #sec2-index {
    display: none; }
  #sec3-index {
    display: none; }
  #sec4-index {
    clear: both;
    float: left;
    width: 100%; }
  #sec4-index figure {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%; }
  #sec5-index figure {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%; }
  #sec1-branchen {
    width: 100%; }
  #sec2-branchen {
    width: 100%;
    padding: 2% 0% 2% 0%; }
  #sec3-branchen {
    width: 100%;
    padding-left: 1%; }
  #sec3-branchen li {
    width: 100%; }
  .sec1-br {
    width: 100%; }
  .sec2-br {
    width: 100%;
    padding-top: 4%;
    margin-right: 0%; }
  #sec2-angebotsspektrum {
    width: 100%; }
  #sec3-angebotsspektrum {
    width: 100%;
    padding: 4% 0% 0% 1%; }
  #sec4-angebotsspektrum {
    width: 100%;
    padding: 4% 0% 0% 1%; }
  #bilder-angebotsspektrum {
    display: none; }
  #sec2-dienstleistungen {
    width: 100%; }
  #sec3-dienstleistungen {
    width: 100%;
    padding-top: 4%;
    margin-right: 0%; }
  #sec3-dienstleistungen img {
    width: 100%; }
  #sec4-dienstleistungen .aussen,
  #sec4-dienstleistungen .innen {
    width: 33%;
    height: 80px; }
  #sec4-dienstleistungen a {
    display: block;
    font-size: 0.9em;
    color: #000;
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    background-image: linear-gradient(#fbfcfd, #c2cddb);
    /* Farbverlauf */
    min-height: 80px; }
  .sec1-dl {
    width: 100%; }
  .sec2-dl {
    width: 100%;
    padding-top: 4%;
    margin-right: 0%; }
  .sec2-dl img {
    padding-top: 1%; }
  .aktuelles-inhalt .text-aktuelles-li {
    width: 100%; }
  .aktuelles-inhalt .bilder-aktuelles-re {
    width: 100%;
    padding-top: 4%; }
  #sec1-messen {
    width: 100%; }
  #sec2-messen {
    width: 100%;
    padding-top: 4%; }
  #sec2-unternehmen {
    width: 100%; }
  #sec3-unternehmen {
    width: 100%;
    padding-top: 4%;
    clear: both;
    margin-right: 0%; }
  .sec2-unt {
    width: 100%; }
  .sec3-unt {
    width: 100%;
    padding-top: 4%;
    margin-right: 0%; }
  #sec2-karriere {
    width: 100%; }
  #sec3-karriere {
    width: 100%;
    padding-top: 4%;
    margin-right: 0%; }
  #sec3-karriere img {
    width: 100%; }
  #sec1-kontakt #links-links-kontakt .aussen,
  #sec1-kontakt #links-links-kontakt .innen {
    width: 31%;
    height: 100%; }
  #sec1-kontakt #links-links-kontakt .innen a {
    display: block;
    color: #000;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.0em;
    text-decoration: none;
    padding: 18px 0;
    background-image: linear-gradient(#ffffff, #a6bad2);
    /* Farbverlauf */
    -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
    -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
    box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86); }
  #sec2-kontakt {
    width: 100%; }
  #sec2-kontakt form {
    width: 100%; }
  #sec3-kontakt {
    width: 100%;
    margin-top: 5%;
    margin-right: 0%;
    padding: 2%; }
  #ansprechpartner ul {
    float: left;
    width: 100%;
    color: #000;
    margin-bottom: 20px;
    margin-left: 1%;
    margin-top: 1%;
    background-color: #c9e1f1; }
  #vertretungen {
    width: 100%;
    margin: 0%; }
  #vertretungen-ueberschrift {
    width: 100%; }
  #titel-anfahrt {
    width: 100%; }
  #google-maps {
    width: 97%;
    margin-top: 2%;
    padding: 0% 0% 0% 3%; }
  #footerinhalt {
    display: none; }
  #sec2-standardsensoren {
    width: 100%; }
  #sec3-standardsensoren {
    width: 100%;
    margin-top: 4%;
    padding-left: 5%; }
  header #lv-titel {
    left: 35px;
    width: 95%;
    letter-spacing: 3px;
    font-size: 24px;
    line-height: 28px; }
  header #header-titel {
    left: 35px;
    width: 95%;
    letter-spacing: 3px;
    font-size: 24px;
    line-height: 28px; }
  #lv-ueberschrift {
    float: left;
    width: 100%;
    padding-left: 1%; }
  #lv-uebersicht {
    margin-top: 4%;
    float: left;
    width: 100%; }
  #lv-uebersicht ul li .lv-weiter a {
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #fff;
    padding: 2px 0;
    margin-right: 35px; }
  #lv-uebersicht ul li .lv-weiter a img {
    width: 40%; }
  #lv-uebersicht ul li .lv-neu .neu {
    width: 16%;
    margin-left: 42%;
    font-size: 0.85em;
    color: red;
    text-align: center;
    background-color: #fff;
    padding: 2px 0;
    box-shadow: 0 0 10px #fff, 0 0 60px #fff, 0 0 150px #fff; }
  #lv-uebersicht ul li .lv-link a {
    font-size: 1.2em; }
  #lv-uebersicht ul li .lv-link a span {
    font-size: 0.8em; }
  #sec1bis2-imp {
    padding-bottom: 0;
    padding-top: 4%; }
  #sec1-imp {
    width: 100%; }
  #sec1-imp h2 {
    font-size: 1.3em;
    padding-left: 5%; }
  #sec2-imp {
    float: left;
    width: 100%;
    margin-right: 0%;
    margin-top: 4%; }
  #sec1-sitemap {
    float: left;
    width: 50%; }
  #sec2und3-sitemap {
    float: right;
    width: 50%; }
  #sec2-sitemap {
    width: 100%; }
  #sec3-sitemap {
    float: left;
    width: 100%;
    margin-top: 35px; }
  #akkordeon img {
    padding-top: 15px; }
  #akkordeon .dreispaltig-acc,
  #akkordeon .frame-dreispaltig-acc {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  #akkordeon div.links-acc,
  #akkordeon div.frame-links-acc {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  #akkordeon div.rechts-acc,
  #akkordeon div.frame-rechts-acc {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px; }
  #akkordeon div.links-acc img,
  #akkordeon div.frame-links-acc img {
    width: auto;
    height: auto;
    padding-left: 10px;
    max-width: 100%; }
  #akkordeon div.rechts-acc img,
  #akkordeon div.frame-rechts-acc img {
    width: auto;
    height: auto;
    max-width: 100%; }
  #akkordeon .dreispaltig-spalte1,
  #akkordeon .frame-dreispaltig-spalte1 {
    float: left;
    width: 100%;
    padding-right: 16px; }
  #akkordeon .dreispaltig-spalte2und3,
  #akkordeon .frame-dreispaltig-spalte2und3 {
    padding-top: 20px;
    float: left;
    width: 100%;
    font-size: 0.85em; }
  #akkordeon .dreispaltig-spalte2und3 .dreispaltig-spalte2,
  #akkordeon .frame-dreispaltig-spalte2und3 .frame-dreispaltig-spalte2 {
    float: left;
    width: 75%;
    margin-left: 0%; }
  #akkordeon .dreispaltig-spalte2und3 .dreispaltig-spalte3,
  #akkordeon .frame-dreispaltig-spalte2und3 .frame-dreispaltig-spalte3 {
    float: left;
    width: 75%; }
  #akkordeon .links-acc .anwendungsgebiete-ulvtgitter,
  #akkordeon .frame-links-acc .anwendungsgebiete-ulvtgitter {
    float: left;
    width: 100%; }
  #akkordeon div.links-acc30,
  #akkordeon div.frame-links-acc30 {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  #akkordeon div.rechts-acc70,
  #akkordeon div.frame-rechts-acc70 {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  #akkordeon div.links-acc30 img,
  #akkordeon div.frame-links-acc30 img {
    width: auto;
    padding-left: 10px; }
  #akkordeon div.rechts-acc70 img,
  #akkordeon div.frame-rechts-acc70 img {
    width: auto;
    height: auto; }
  #kontakt-formular {
    float: left;
    width: 100%;
    padding-top: 20px;
    margin-left: 1%;
    padding-right: 1%; }
  #kontakt-links {
    float: left;
    width: 100%;
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%; }
  #kontakt-rechts {
    float: right;
    width: 100%;
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%; } }

/*++++++++++++++++++++++++++++++++++++++++++++++++
Media Query 600px
++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (max-width: 600px) {
  nav:not(.frame-default) ul li {
    height: 40px;
    line-height: 30px; }
  .tx-kesearch-pi1 {
    width: 80px !important; }
  #meta input[type=text] {
    max-width: 80px; }
  #breadcrumb {
    display: none; }
  #meta {
    position: absolute;
    top: 10px;
    left: 30%;
    width: 75%;
    /* 836 / 1100 */
    z-index: 10000; }
  #meta ul {
    float: left;
    /* �nderung Swen Bauer 24.01.2017 - H�he angepasst f�r mobile Devices */
    height: 20px; }
  #logo {
    position: absolute;
    top: 10px;
    left: 1.090909090909091%;
    /* 12 / 1100 */
    width: 20.909090909090907%;
    /* 230 / 1100 */
    z-index: 10000; }
  #sec4-dienstleistungen .aussen,
  #sec4-dienstleistungen .innen {
    width: 50%;
    height: 80px; }
  #sec4-dienstleistungen a {
    display: block;
    font-size: 0.9em;
    color: #000;
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    background-image: linear-gradient(#fbfcfd, #c2cddb);
    /* Farbverlauf */
    min-height: 80px; }
  #sec1-kontakt #links-links-kontakt .aussen,
  #sec1-kontakt #links-links-kontakt .innen {
    width: 100%;
    height: 100%;
    margin-top: 2%; }
  #sec1-kontakt #links-links-kontakt .innen a {
    display: block;
    color: #000;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.0em;
    text-decoration: none;
    padding: 18px 0;
    background-image: linear-gradient(#ffffff, #a6bad2);
    /* Farbverlauf */
    -webkit-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
    -moz-box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86);
    box-shadow: inset -16px -25px 24px -8px rgba(166, 186, 210, 0.86); }
  #ansprechpartner ul li {
    padding-left: 10px;
    padding-bottom: 8px; }
  #ansprechpartner ul li:nth-of-type(1) {
    width: 100%;
    padding: 3% 2% 3% 2%; }
  #ansprechpartner ul li:nth-of-type(1) img {
    width: 100%; }
  #ansprechpartner ul li:nth-of-type(2) {
    font-weight: bold;
    padding: 15px 10px; }
  #ansprechpartner ul li:nth-of-type(3) {
    padding-top: 20px; }
  #vertretungen .vertretungen-links {
    width: 100%; }
  #vertretungen .vertretungen-rechts {
    width: 100%; }
  header #lv-titel {
    top: 58%;
    left: 10px;
    width: 95%;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 16px; }
  header #header-titel {
    top: 58%;
    left: 10px;
    width: 95%;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 16px; }
  #lv-uebersicht ul li {
    float: left;
    width: 100%;
    list-style-type: none;
    padding-right: 1%; }
  #lv-uebersicht ul li .lv-link a {
    font-size: 1.6em; }
  #lv-uebersicht ul li .lv-link a span {
    font-size: 0.8em; }
  #lv-uebersicht ul li .lv-neu .neu {
    width: 16%;
    margin-left: 42%;
    font-size: 1.0em; }
  #sec1-sitemap {
    width: 100%; }
  #sec2und3-sitemap {
    margin-top: 5%;
    float: left;
    width: 100%; }
  #akkordeon div.links-acc img,
  #akkordeon div.frame-links-acc img {
    width: 100%;
    padding-left: 10px; }
  #akkordeon div.rechts-acc img,
  #akkordeon div.frame-rechts-acc img {
    width: 100%; }
  #akkordeon div.absatz-acc img {
    width: 100%; }
  #akkordeon div.links-acc30 img,
  #akkordeon div.frame-links-acc30 img {
    width: auto;
    height: auto;
    padding-left: 10px; }
  #akkordeon div.rechts-acc70 img,
  #akkordeon div.frame-rechts-acc70 img {
    width: auto;
    height: auto; }
  #akkordeon table img {
    padding-top: 0px;
    width: 26px !important;
    height: 26px; }
  #lv-uebersicht ul li .lv-desc ul li {
    clear: both !important;
    width: 90%;
    list-style-type: square;
    padding-right: 0%;
    margin-left: 30px;
    font-size: 13px; } }

form.powermail_form > ul.powermail_message_error {
  bottom: auto !important;
  top: -20px !important;
  left: 0 !important; }

form.powermail_form > ul.powermail_message_error li {
  font-size: 15px !important; }

/*
 Frame classes
 */
.frame-space-before-10px {
  margin-top: 10px; }

.frame-space-after-10px {
  margin-bottom: 10px; }

.frame-space-before-20px {
  margin-top: 20px; }

.frame-space-after-20px {
  margin-bottom: 20px; }

.frame-space-before-30px {
  margin-top: 30px; }

.frame-space-after-30px {
  margin-bottom: 30px; }

.frame-space-before-40px {
  margin-top: 40px; }

.frame-space-after-40px {
  margin-bottom: 40px; }

.frame-space-before-50px {
  margin-top: 50px; }

.frame-space-after-50px {
  margin-bottom: 50px; }

.frame-space-before-60px {
  margin-top: 60px; }

.frame-space-after-60px {
  margin-bottom: 60px; }

.frame-space-before-70px {
  margin-top: 70px; }

.frame-space-after-70px {
  margin-bottom: 70px; }

.frame-space-before-80px {
  margin-top: 80px; }

.frame-space-after-80px {
  margin-bottom: 80px; }

.frame-space-before-90px {
  margin-top: 90px; }

.frame-space-after-90px {
  margin-bottom: 90px; }

.frame-space-before-100px {
  margin-top: 100px; }

.frame-space-after-100px {
  margin-bottom: 100px; }

.frame-space-before-110px {
  margin-top: 110px; }

.frame-space-after-110px {
  margin-bottom: 110px; }

.frame-space-before-120px {
  margin-top: 120px; }

.frame-space-after-120px {
  margin-bottom: 120px; }

.frame-space-before-130px {
  margin-top: 130px; }

.frame-space-after-130px {
  margin-bottom: 130px; }

.frame-space-before-140px {
  margin-top: 140px; }

.frame-space-after-140px {
  margin-bottom: 140px; }

.frame-space-before-150px {
  margin-top: 150px; }

.frame-space-after-150px {
  margin-bottom: 150px; }

.frame-space-before-160px {
  margin-top: 160px; }

.frame-space-after-160px {
  margin-bottom: 160px; }

.frame-space-before-170px {
  margin-top: 170px; }

.frame-space-after-170px {
  margin-bottom: 170px; }

.frame-space-before-180px {
  margin-top: 180px; }

.frame-space-after-180px {
  margin-bottom: 180px; }

.frame-space-before-190px {
  margin-top: 190px; }

.frame-space-after-190px {
  margin-bottom: 190px; }

.frame-space-before-200px {
  margin-top: 200px; }

.frame-space-after-200px {
  margin-bottom: 200px; }

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
  margin-bottom: 0; }

.ce-intext.ce-right .ce-gallery .image,
.ce-intext.ce-left .ce-gallery .image,
.ce-above .ce-gallery .image {
  margin-bottom: 10px; }

/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */
/* Welcome to Compass. Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" type="text/css" />
 * <![endif]--> */
#nav-main {
  width: 1190px;
  height: 30px;
  margin: 0 auto; }
  #nav-main > ul {
    list-style-type: none;
    width: 1190px;
    margin: 0 auto;
    position: relative; }
  #nav-main > ul > li {
    width: 170px;
    height: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    float: left;
    background-color: #C0CCDA;
    border-left: solid 1px #fff;
    border-right: solid 1px #7E97B4;
    width: 170px;
    height: 30px;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-top: 8px;
    text-decoration: none;
    color: #333;
    cursor: pointer; }
  #nav-main > ul > li:first-child {
    border-left: none; }
  #nav-main > ul > li:last-child {
    border-right: none; }
  #nav-main > ul > li.active {
    color: #fff;
    background-color: #990000; }
  #nav-main > ul > li:hover {
    color: #fff;
    background-color: #005798; }
  #nav-main > ul > li.focus {
    color: #fff;
    background-color: #005798; }
  #nav-main .submenu {
    width: 1190px;
    border: solid 1px #ccc;
    background: rgba(255, 255, 255, 0.9);
    z-index: 300;
    position: absolute;
    top: 30px;
    left: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 2px 7px #999;
    display: none; }
    #nav-main .submenu a {
      width: 170px;
      min-height: 25px;
      display: block;
      text-decoration: none;
      padding: 5px 0;
      padding-left: 2px;
      color: #333;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      line-height: 15px;
      font-weight: bold;
      text-align: left; }
      #nav-main .submenu a:hover {
        background-color: #ccc; }
    #nav-main .submenu > a {
      font-size: 16px;
      font-weight: bold;
      color: #990000;
      text-decoration: none;
      outline: none;
      border: none;
      width: 1180px;
      text-align: left;
      padding-left: 20px; }
      #nav-main .submenu > a:hover {
        background: none; }
    #nav-main .submenu li {
      float: left;
      width: 170px;
      min-height: 25px; }
    #nav-main .submenu > ul > li {
      margin-left: 20px; }
    #nav-main .submenu .subsubmenu a {
      width: 170px;
      min-height: 25px;
      display: block;
      padding-left: 10px;
      font-weight: normal;
      color: #000; }
    #nav-main .submenu .subsubsubmenu li a {
      width: 170px;
      min-height: 20px;
      display: block;
      font-weight: normal;
      padding-left: 15px;
      color: #666;
      word-break: initial; }

powermail_confirmation_submit,
.powermail_confirmation_form {
  width: 100%;
  display: block;
  line-height: 16px;
  border: solid 1px #999;
  background: #990000;
  text-align: center;
  color: white;
  margin: 10px 0; }

form.powermail_form {
  position: relative; }

form.powermail_form > ul.powermail_message_error {
  top: 0;
  bottom: initial; }

form.powermail_form > h3 {
  display: none; }

form.powermail_form > fieldset > legend {
  display: none; }

form.powermail_form > fieldset > div {
  margin-bottom: 30px; }

form.powermail_form div.powermail_fieldwrap_radio,
form.powermail_form div.powermail_fieldwrap_check {
  margin-bottom: 0; }

form.powermail_form div[class*="field-"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  float: left;
  padding-left: 15px; }

form.powermail_form div[class*="field-left-"] {
  padding: 0; }

form.powermail_form div.field-10,
form.powermail_form div.field-left-10 {
  width: 10%; }

form.powermail_form div.field-20,
form.powermail_form div.field-left-20 {
  width: 20%; }

form.powermail_form div.field-25,
form.powermail_form div.field-left-25 {
  width: 25%; }

form.powermail_form div.field-30,
form.powermail_form div.field-left-30 {
  width: 30%; }

form.powermail_form div.field-40,
form.powermail_form div.field-left-40 {
  width: 40%; }

form.powermail_form div.field-50,
form.powermail_form div.field-left-50 {
  width: 50%; }

form.powermail_form div.field-60,
form.powermail_form div.field-left-60 {
  width: 60%; }

form.powermail_form div.field-70,
form.powermail_form div.field-left-70 {
  width: 70%; }

form.powermail_form div.field-80,
form.powermail_form div.field-left-80 {
  width: 80%; }

form.powermail_form div.field-90,
form.powermail_form div.field-left-90 {
  width: 90%; }

form.powermail_form div {
  position: relative;
  display: inline-block;
  width: 100%; }

form.powermail_form ul {
  position: absolute;
  right: -1px;
  bottom: 18px;
  background-color: #ff0000;
  opacity: 0.5; }

form.powermail_form ul li {
  color: white;
  list-style: none;
  margin: 0;
  padding: 3px;
  font-size: 12px;
  line-height: 11px;
  text-align: right; }

form.powermail_form div.powermail_tab_navigation a {
  width: 100%;
  display: block;
  line-height: 16px;
  border: solid 1px #999;
  background: #990000;
  text-align: center;
  color: white;
  margin: 10px 0; }

form.powermail_form #powermail_tabmenu {
  position: absolute;
  background: none;
  opacity: 1;
  right: 0;
  top: -42px; }

form.powermail_form #powermail_tabmenu li {
  display: none;
  font-size: 15px;
  padding: 10px 0 0 0;
  font-weight: normal;
  color: #005798;
  line-height: 18px; }

form.powermail_form #powermail_tabmenu .act {
  display: block; }

form.powermail_form #powermail_tabmenu .act:focus {
  border: none !important; }

form.powermail_form .powermail_radio_inner {
  width: 20%;
  float: left; }

form.powermail_form .powermail_fieldwrap_text {
  font-size: 16px;
  color: #333; }

div.powermail_tab_navigation a:hover {
  text-decoration: none; }

div.powermail_tab_navigation a.powermail_tab_navigation_next,
div.powermail_tab_navigation a.powermail_tab_navigation_previous {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 50%;
  float: left; }

div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "N\0000E4chste Schritt "; }

#body_en div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "Continue "; }

#body_fr div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "Continuer "; }

div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Vorheriger Schritt"; }

#body_en div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Back"; }

#body_fr div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Arri\0000E8re"; }

input[type=checkbox],
input[type=radio] {
  width: auto;
  margin: 0; }

label {
  vertical-align: text-bottom;
  line-height: 20px; }

/* Formulare*/
.formrow {
  width: 700px;
  min-height: 50px; }

.formcol_left {
  width: 340px;
  float: left; }

.formcol_right {
  width: 340px;
  float: left;
  margin-left: 20px; }

.formcol_plz {
  width: 140px;
  float: left; }

.formcol_ort {
  width: 540px;
  float: left;
  margin-left: 20px; }

.formcol_strasse {
  width: 630px;
  float: left; }

.formcol_nr {
  width: 50px;
  float: left;
  margin-left: 20px; }

.formcol_100 {
  width: 700px; }

.formcol_check {
  width: 20px;
  float: left; }

.formcol_check2 {
  width: 660px;
  float: left;
  margin-left: 20px;
  line-height: 20px; }

input,
textarea {
  width: 100%;
  border: solid 1px #999; }

textarea {
  min-height: 150px; }

sw_callme {
  float: left; }

.Spezial {
  display: none; }

label {
  font-size: 11px;
  color: #666; }

#sw_anhang {
  border: none; }

#slider-container .nivo-caption {
  display: none !important; }

#slider-container.slider-body {
  width: 700px;
  height: auto;
  float: left;
  position: relative;
  background-color: #eee;
  margin-bottom: 45px; }

#slider-container.slider-body .slider-wrapper {
  height: auto !important;
  padding-top: 25px;
  position: relative;
  background-color: #fff;
  overflow: visible; }

#slider-container.slider-body .slider-wrapper img {
  border: solid 1px #ccc; }

#slider-container.slider-body .nivo-directionNav {
  display: none; }

#slider-container.slider-body .nivo-controlNav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0; }

#slider-container.slider-body .nivo-controlNav a {
  text-indent: -10000px;
  width: 20px;
  height: 10px;
  background: none;
  background-color: #ccc;
  display: block;
  float: left;
  margin: 0 !important;
  margin-left: 5px !important;
  margin-top: 10px !important;
  outline: none;
  border: none; }

#slider-container.slider-body .nivo-controlNav a.active {
  background-color: #990000; }

#slider-container.slider-body .nivo-caption {
  display: block !important;
  width: 702px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  display: block;
  background-color: #666;
  padding: 0;
  padding-left: 10px;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  position: absolute;
  bottom: -25px; }

#slider-container.slider-body .nivo-caption p {
  color: #fff;
  font-size: 13px;
  padding: 0;
  font-weight: normal;
  padding: 3px 0; }

#slider-container.slider-body .nivo-caption a.hover {
  text-decoration: underline; }

#slider-container.slider-body .nivoSlider {
  overflow: visible; }

#slider .theme-product {
  background: none; }

#main-container.bodyslider #content1-container {
  width: 464px;
  float: left;
  min-height: 200px; }

#main-container.bodyslider #content2-container {
  width: 706px;
  float: left;
  margin-left: 20px;
  min-height: 200px; }

#main-container.bodyslider #content3-container {
  width: 1190px; }

.slider-wrapper {
  background: transparent url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/nivo/loading.gif") no-repeat 50% 50%; }

.nivoSlider {
  position: relative;
  /*	background:#fff url(loading.gif) no-repeat 50% 50%;*/
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 1px 5px 0px #4a4a4a;
  -moz-box-shadow: 0px 1px 5px 0px #4a4a4a;
  box-shadow: 0px 1px 5px 0px #4a4a4a; }
  .nivoSlider img {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none; }
  .nivoSlider a {
    border: 0;
    display: block; }

.nivo-controlNav {
  text-align: center;
  margin-top: -10px;
  padding: 0; }
  .nivo-controlNav a {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/nivo/bullets.png") no-repeat;
    text-indent: -9999px;
    border: 0;
    margin: 0 2px; }
    .nivo-controlNav a.active {
      background-position: 0 -22px; }

.nivo-directionNav a {
  display: block;
  width: 30px;
  height: 30px;
  background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/nivo/arrows.png") no-repeat;
  text-indent: -9999px;
  border: 0;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

div.slider-wrapper:hover .nivo-directionNav a {
  opacity: 1; }

a.nivo-nextNav {
  background-position: -30px 0;
  right: 15px; }

a.nivo-prevNav {
  left: 15px; }

.nivo-caption a {
  color: #fff;
  border-bottom: 1px dotted #fff; }

.nivo-caption a:hover {
  color: #fff; }

.nivo-controlNav.nivo-thumbs-enabled {
  width: 100%; }

.nivo-controlNav.nivo-thumbs-enabled a {
  width: auto;
  height: auto;
  background: none;
  margin-bottom: 5px; }

.nivo-controlNav.nivo-thumbs-enabled img {
  display: block;
  width: 120px;
  height: auto; }

div.teaser img,
div.teaser-large img,
div.frame-teaser img,
div.frame-teaser-large img {
  border-bottom-right-radius: 15px;
  -webkit-border-bottom-right-radius: 15px;
  -moz-border-bottom-right-radius: 15px; }

div.teaser .csc-header,
div.teaser-large .csc-header,
div.frame-teaser .csc-header,
div.frame-teaser-large .csc-header {
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  -moz-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px;
  font-size: 14px; }

div.teaser img,
div.frame-teaser img {
  border-bottom-left-radius: 15px;
  -webkit-border-bottom-left-radius: 15px;
  -moz-border-bottom-left-radius: 15px; }

div.teaser-large,
div.frame-teaser-large {
  width: 460px;
  height: 220px;
  float: left;
  background-color: #CFCED9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }

div.teaser-large .csc-header,
div.frame-teaser-large .csc-header {
  width: 460px;
  height: 25px;
  line-height: 25px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  background-color: #990000;
  padding-left: 10px;
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  -moz-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px; }

div.teaser-large .csc-header h1,
div.frame-teaser-large .csc-header h1 {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  margin-top: 0px; }

div.teaser-large .image img,
div.frame-teaser-large .image img {
  height: 195px; }

div.teaser-large .ce-textpic,
div.frame-teaser-large .ce-textpic {
  width: 460px; }

div.teaser-large .ce-textpic .ce-bodytext,
div.frame-teaser-large .ce-textpic .ce-bodytext {
  width: 240px;
  float: left;
  margin-top: 10px;
  margin-left: 10px; }

div.teaser-large .ce-textpic .ce-gallery,
div.frame-teaser-large .ce-textpic .ce-gallery {
  width: 200px;
  float: right;
  margin-left: 10px; }

div.teaser,
div.frame-teaser {
  margin-left: 23px; }

div.teaser,
div.teaser-img,
div.frame-teaser,
div.frame-teaser-img {
  width: 220px;
  height: 220px;
  float: left;
  background-color: #CFCED9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  overflow: hidden; }

div.teaser .csc-header,
div.teaser-img .csc-header,
div.frame-teaser .csc-header,
div.frame-teaser-img .csc-header {
  width: 220px;
  height: 25px;
  line-height: 25px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  background-color: #C0CCDA;
  padding-left: 10px; }

div.teaser .csc-header h1,
div.teaser-img .csc-header h1,
div.frame-teaser .csc-header h1,
div.frame-teaser-img .csc-header h1 {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin-top: 0px; }

div.teaser-img,
div.frame-teaser-img {
  background-color: #fff; }

div.teaser-img .csc-header,
div.frame-teaser-img .csc-header {
  display: none; }

div.teaser-img img,
div.frame-teaser-img img {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: solid 1px #ccc; }

div.image-large {
  width: 464px;
  float: left;
  min-height: 200px; }
  div.image-large .image img {
    border-radius: 15px;
    border: solid 1px #ccc;
    border-radius: 15px; }

div.speakerbox {
  float: right;
  width: 460px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }

div.speakerbox-large {
  width: 700px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }

div.messe {
  width: 700px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }
  div.messe ul {
    padding: 20px 20px 20px 5px; }

div.info {
  width: 220px;
  height: 220px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid 1px #ccc;
  display: block;
  background-color: #eee;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  float: left;
  margin-left: 20px; }
  div.info .csc-header {
    width: 220px;
    min-height: 25px;
    background-color: #C0CCDA;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 7px 0 5px 10px;
    cursor: pointer; }
  div.info .csc-header h1 {
    font-size: 12px;
    color: #333;
    font-weight: normal; }
  div.info .csc-menu {
    width: 220px;
    height: 193px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: 25px;
    left: 0px;
    background-color: #F4F4F4;
    overflow: hidden;
    overflow-y: scroll;
    scroll: y-scroll;
    z-index: 3;
    font-size: 12px;
    line-height: 18px;
    list-style-type: square; }
  div.info .overlay-img {
    width: 220px;
    height: 193px;
    position: absolute;
    top: 25px;
    left: 0px;
    background: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/shared/infobg.png");
    cursor: pointer;
    z-index: 100; }
  div.info li {
    margin-top: 5px;
    margin-left: 10px;
    width: 180px;
    min-height: 20px; }
  div.info a {
    width: 180px;
    min-height: 20px;
    display: block;
    text-decoration: none; }

div.product a,
div.productsmall a,
div.teaser a,
div.teaser-large a,
div.frame-teaser a,
div.frame-teaser-large a {
  font-size: 14px;
  color: #990000;
  text-decoration: none; }
  div.product a:after,
  div.productsmall a:after,
  div.teaser a:after,
  div.teaser-large a:after,
  div.frame-teaser a:after,
  div.frame-teaser-large a:after {
    content: url("/typo3conf/ext/fiessler_theme/Resources/Public/Images/shared/arrow_more.png"); }

div.product .image a:after,
div.productsmall .image a:after,
div.teaser .image a:after,
div.teaser-large .image a:after,
div.frame-teaser .image a:after,
div.frame-teaser-large .image a:after {
  content: none; }

div.product,
div.productsmall {
  width: 220px;
  height: 220px;
  margin-bottom: 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid 1px #ccc;
  display: block;
  background-color: #eee;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  float: left; }
  div.product .csc-header,
  div.productsmall .csc-header {
    width: 218px;
    min-height: 45px;
    background-color: #C0CCDA;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 7px 0 5px 10px;
    cursor: pointer; }
  div.product .csc-header h1,
  div.productsmall .csc-header h1 {
    color: #333;
    font-size: 12px;
    font-weight: normal; }
  div.product .image,
  div.productsmall .image {
    width: 218px;
    height: 203px;
    position: absolute;
    top: 35px;
    left: 0px;
    z-index: 100;
    cursor: pointer; }
  div.product .ce-bodytext,
  div.productsmall .ce-bodytext {
    height: 183px;
    width: 218px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: 35px;
    left: 0px;
    background-color: #F4F4F4;
    overflow: hidden;
    overflow-y: scroll;
    scroll: y-scroll;
    z-index: 3;
    font-size: 12px;
    line-height: 18px; }
  div.product .ce-bodytext ul,
  div.productsmall .ce-bodytext ul {
    list-style-type: square;
    margin-left: 10px; }
  div.product .ce-bodytext li,
  div.product .ce-bodytext-text li,
  div.productsmall .ce-bodytext li,
  div.productsmall .ce-bodytext-text li {
    margin: 0;
    margin-top: 5px;
    width: 180px;
    min-height: 20px; }
  div.product .ce-bodytext p,
  div.productsmall .ce-bodytext p {
    line-height: 20px;
    color: #333;
    font-size: 12px;
    margin-top: 10px; }
  div.product ~ div.product,
  div.product ~ div.productsmall,
  div.productsmall ~ div.product,
  div.productsmall ~ div.productsmall {
    margin-left: 21px; }

div.productsmall {
  height: 220px; }
  div.productsmall .csc-header {
    min-height: 25px; }
  div.productsmall .image {
    top: 25px; }
  div.productsmall .ce-bodytext {
    top: 25px; }
  div.productsmall .ce-bodytext {
    height: 193px; }

div.producttext {
  width: 464px;
  float: left;
  min-height: 200px; }

#content3-container-product {
  width: 700px;
  float: right; }
  #content3-container-product div.product:nth-of-type(5n+1),
  #content3-container-product div.productsmall:nth-of-type(5n+1) {
    margin-left: 20px; }
  #content3-container-product div.product:nth-of-type(3n+1),
  #content3-container-product div.productsmall:nth-of-type(3n+1) {
    margin-left: 0; }

div.content-aside {
  width: 706px;
  float: left;
  min-height: 200px; }

div.aside {
  float: left;
  min-height: 200px;
  width: 460px;
  margin: 0 auto;
  margin-left: 24px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }

.ce-gallery {
  list-style-type: none !important; }
  .ce-gallery li {
    margin: 0 !important; }

#body_de .translate01:before {
  content: "ANGEBOT"; }

#body_en .translate01:before {
  content: "QUOTATION"; }

#body_fr .translate01:before {
  content: "OFFRIR"; }

#body_tr .translate01:before {
  content: "TEKLİF"; }

#body_de .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Telefon: +49 (0) 711/91 96 97-0, Telefax: +49 (0) 711/91 96 97-50, "; }

#body_en .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, "; }

#body_fr .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, "; }

#body_tr .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, "; }

#body_de .translate03:before {
  content: "Startseite"; }

#body_en .translate03:before {
  content: "Start"; }

#body_fr .translate03:before {
  content: "Start"; }

#body_tr .translate03:before {
  content: "Başlat"; }

#body_de .translate04:before {
  content: "> Angebot anfordern"; }

#body_en .translate04:before {
  content: "> Request quotation"; }

#body_fr .translate04:before {
  content: "> Demandez l'offre"; }

#body_tr .translate04:before {
  content: "> Teklif iste"; }

#body_de .translate05:before {
  content: "Angebotsspektrum"; }

#body_en .translate05:before {
  content: "Product portfolio"; }

#body_fr .translate05:before {
  content: "Gamme d'offres"; }

#body_tr .translate05:before {
  content: "Ürün portföyü"; }

#body_de .translate06:before {
  content: "Dienstleistungen"; }

#body_en .translate06:before {
  content: "Services"; }

#body_fr .translate06:before {
  content: "Service"; }

#body_tr .translate06:before {
  content: "Hizmet"; }

#body_de .translate07:before {
  content: "Branchen"; }

#body_en .translate07:before {
  content: "Sectors of industry"; }

#body_fr .translate07:before {
  content: "Branches d'activi\0000E9"; }

#body_tr .translate07:before {
  content: "Sanayi sektörleri"; }

#body_de .translate08:before {
  content: "Aktuelles"; }

#body_en .translate08:before {
  content: "News"; }

#body_fr .translate08:before {
  content: "Actualit\0000E9s"; }

#body_tr .translate08:before {
  content: "Haberler"; }

#body_de .translate09:before {
  content: "Unternehmen"; }

#body_en .translate09:before {
  content: "Company"; }

#body_fr .translate09:before {
  content: "Entreprise"; }

#body_tr .translate09:before {
  content: "şirket"; }

#body_de .translate10:before {
  content: "Karriere"; }

#body_en .translate10:before {
  content: "Careers"; }

#body_fr .translate10:before {
  content: "Carri\0000E8res"; }

#body_tr .translate10:before {
  content: "Kariyer"; }

#body_de .translate11:before {
  content: "Kontakt"; }

#body_en .translate11:before {
  content: "Contact"; }

#body_fr .translate11:before {
  content: "Contact"; }

#body_tr .translate11:before {
  content: "İletişim"; }

#body_de .translate12:before {
  content: "CLASSIC"; }

#body_en .translate12:before {
  content: "CLASSIC"; }

#body_fr .translate12:before {
  content: "CLASSIC"; }

#body_tr .translate12:before {
  content: "CLASSIC"; }

#body_de #btn-acc > a:after {
  content: "Angebot anfordern"; }

#body_en #btn-acc > a:after {
  content: "Request quote"; }

#body_fr #btn-acc > a:after {
  content: "Demander un devis"; }

#body_tr #btn-acc > a:after {
  content: "Teklif iste"; }

#body_en .teaser-products .teaser-product figcaption a,
#body_en .teaser-products .frame-teaser-product figcaption a,
#body_en .frame-teaser-products .teaser-product figcaption a,
#body_en .frame-teaser-products .frame-teaser-product figcaption a, #body_fr .teaser-products .teaser-product figcaption a,
#body_fr .teaser-products .frame-teaser-product figcaption a,
#body_fr .frame-teaser-products .teaser-product figcaption a,
#body_fr .frame-teaser-products .frame-teaser-product figcaption a, #body_tr .teaser-products .teaser-product figcaption a,
#body_tr .teaser-products .frame-teaser-product figcaption a,
#body_tr .frame-teaser-products .teaser-product figcaption a,
#body_tr .frame-teaser-products .frame-teaser-product figcaption a {
  text-indent: -115px; }
