powermail_confirmation_submit,
.powermail_confirmation_form {
  width: 100%;
  display: block;
  line-height: 16px;
  border: solid 1px #999;
  background: #990000;
  text-align: center;
  color: white;
  margin: 10px 0;
}

form.powermail_form {
  position: relative;
}

form.powermail_form > ul.powermail_message_error {
  top: 0;
  bottom: initial;
}

form.powermail_form > h3 {
  display: none;
}

form.powermail_form > fieldset > legend {
  display: none;
}

form.powermail_form > fieldset > div {
  margin-bottom: 30px;
}

form.powermail_form div.powermail_fieldwrap_radio,
form.powermail_form div.powermail_fieldwrap_check {
  margin-bottom: 0;
}

form.powermail_form div[class*="field-"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  float: left;
  padding-left: 15px;
}

form.powermail_form div[class*="field-left-"] {
  padding: 0;
}

form.powermail_form div.field-10,
form.powermail_form div.field-left-10 {
  width: 10%;
}

form.powermail_form div.field-20,
form.powermail_form div.field-left-20 {
  width: 20%;
}

form.powermail_form div.field-25,
form.powermail_form div.field-left-25 {
  width: 25%;
}

form.powermail_form div.field-30,
form.powermail_form div.field-left-30 {
  width: 30%;
}

form.powermail_form div.field-40,
form.powermail_form div.field-left-40 {
  width: 40%;
}

form.powermail_form div.field-50,
form.powermail_form div.field-left-50 {
  width: 50%;
}

form.powermail_form div.field-60,
form.powermail_form div.field-left-60 {
  width: 60%;
}

form.powermail_form div.field-70,
form.powermail_form div.field-left-70 {
  width: 70%;
}

form.powermail_form div.field-80,
form.powermail_form div.field-left-80 {
  width: 80%;
}

form.powermail_form div.field-90,
form.powermail_form div.field-left-90 {
  width: 90%;
}

form.powermail_form div {
  position: relative;
  display: inline-block;
  width: 100%;
}

form.powermail_form ul {
  position: absolute;
  right: -1px;
  bottom: 18px;
  background-color: #ff0000;
  opacity: 0.5;
}

form.powermail_form ul li {
  color: white;
  list-style: none;
  margin: 0;
  padding: 3px;
  font-size: 12px;
  line-height: 11px;
  text-align: right;
}

form.powermail_form div.powermail_tab_navigation a {
  width: 100%;
  display: block;
  line-height: 16px;
  border: solid 1px #999;
  background: #990000;
  text-align: center;
  color: white;
  margin: 10px 0;
}

form.powermail_form #powermail_tabmenu {
  position: absolute;
  background: none;
  opacity: 1;
  right: 0;
  top: -42px;
}

form.powermail_form #powermail_tabmenu li {
  display: none;
  font-size: 15px;
  padding: 10px 0 0 0;
  font-weight: normal;
  color: #005798;
  line-height: 18px;
}

form.powermail_form #powermail_tabmenu .act {
  display: block;
}

form.powermail_form #powermail_tabmenu .act:focus {
  border: none !important;
}

form.powermail_form .powermail_radio_inner {
  width: 20%;
  float: left;
}

form.powermail_form .powermail_fieldwrap_text {
  font-size: 16px;
  color: #333;
}

div.powermail_tab_navigation a:hover {
  text-decoration: none;
}

div.powermail_tab_navigation a.powermail_tab_navigation_next,
div.powermail_tab_navigation a.powermail_tab_navigation_previous {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 50%;
  float: left;
}

div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "N\0000E4chste Schritt ";
}

#body_en div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "Continue ";
}

#body_fr div.powermail_tab_navigation a.powermail_tab_navigation_next:before {
  content: "Continuer ";
}

div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Vorheriger Schritt";
}

#body_en div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Back";
}

#body_fr div.powermail_tab_navigation a.powermail_tab_navigation_previous:after {
  content: " Arri\0000E8re";
}

input[type=checkbox],
input[type=radio] {
  width: auto;
  margin: 0;
}

label {
  vertical-align: text-bottom;
  line-height: 20px;
}

/* Formulare*/
.formrow {
  width: 700px;
  min-height: 50px;
}

.formcol_left {
  width: 340px;
  float: left;
}

.formcol_right {
  width: 340px;
  float: left;
  margin-left: 20px;
}

.formcol_plz {
  width: 140px;
  float: left;
}

.formcol_ort {
  width: 540px;
  float: left;
  margin-left: 20px;
}

.formcol_strasse {
  width: 630px;
  float: left;
}

.formcol_nr {
  width: 50px;
  float: left;
  margin-left: 20px;
}

.formcol_100 {
  width: 700px;
}

.formcol_check {
  width: 20px;
  float: left;
}

.formcol_check2 {
  width: 660px;
  float: left;
  margin-left: 20px;
  line-height: 20px;
}

input,
textarea {
  width: 100%;
  border: solid 1px #999;
}

textarea {
  min-height: 150px;
}

sw_callme {
  float: left;
}

.Spezial {
  display: none;
}

label {
  font-size: 11px;
  color: #666;
}

#sw_anhang {
  border: none;
}
