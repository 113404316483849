#body_de .translate01:before {
  content: "ANGEBOT";
}

#body_en .translate01:before {
  content: "QUOTATION";
}

#body_fr .translate01:before {
  content: "OFFRIR";
}

#body_tr .translate01:before {
  content: "TEKLİF";
}

#body_de .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Telefon: +49 (0) 711/91 96 97-0, Telefax: +49 (0) 711/91 96 97-50, ";
}

#body_en .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, ";
}

#body_fr .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, ";
}

#body_tr .translate02:before {
  content: "Fiessler Elektronik GmbH & Co. KG, Buchenteich 14, D-73773 Aichwald, Phone: +49 (0) 711/91 96 97-0, Fax: +49 (0) 711/91 96 97-50, ";
}

#body_de .translate03:before {
  content: "Startseite";
}

#body_en .translate03:before {
  content: "Start";
}

#body_fr .translate03:before {
  content: "Start";
}

#body_tr .translate03:before {
  content: "Başlat";
}

#body_de .translate04:before {
  content: "> Angebot anfordern";
}

#body_en .translate04:before {
  content: "> Request quotation";
}

#body_fr .translate04:before {
  content: "> Demandez l'offre";
}

#body_tr .translate04:before {
  content: "> Teklif iste";
}

#body_de .translate05:before {
  content: "Angebotsspektrum";
}

#body_en .translate05:before {
  content: "Product portfolio";
}

#body_fr .translate05:before {
  content: "Gamme d'offres";
}

#body_tr .translate05:before {
  content: "Ürün portföyü";
}

#body_de .translate06:before {
  content: "Dienstleistungen";
}

#body_en .translate06:before {
  content: "Services";
}

#body_fr .translate06:before {
  content: "Service";
}

#body_tr .translate06:before {
  content: "Hizmet";
}

#body_de .translate07:before {
  content: "Branchen";
}

#body_en .translate07:before {
  content: "Sectors of industry";
}

#body_fr .translate07:before {
  content: "Branches d'activi\0000E9";
}

#body_tr .translate07:before {
  content: "Sanayi sektörleri";
}

#body_de .translate08:before {
  content: "Aktuelles";
}

#body_en .translate08:before {
  content: "News";
}

#body_fr .translate08:before {
  content: "Actualit\0000E9s";
}

#body_tr .translate08:before {
  content: "Haberler";
}

#body_de .translate09:before {
  content: "Unternehmen";
}

#body_en .translate09:before {
  content: "Company";
}

#body_fr .translate09:before {
  content: "Entreprise";
}

#body_tr .translate09:before {
  content: "şirket";
}

#body_de .translate10:before {
  content: "Karriere";
}

#body_en .translate10:before {
  content: "Careers";
}

#body_fr .translate10:before {
  content: "Carri\0000E8res";
}

#body_tr .translate10:before {
  content: "Kariyer";
}

#body_de .translate11:before {
  content: "Kontakt";
}

#body_en .translate11:before {
  content: "Contact";
}

#body_fr .translate11:before {
  content: "Contact";
}

#body_tr .translate11:before {
  content: "İletişim";
}

#body_de .translate12:before {
  content: "CLASSIC";
}

#body_en .translate12:before {
  content: "CLASSIC";
}

#body_fr .translate12:before {
  content: "CLASSIC";
}

#body_tr .translate12:before {
  content: "CLASSIC";
}

#body_de #btn-acc > a:after {
  content: "Angebot anfordern";
}

#body_en #btn-acc > a:after {
  content: "Request quote";
}

#body_fr #btn-acc > a:after {
  content: "Demander un devis";
}

#body_tr #btn-acc > a:after {
  content: "Teklif iste";
}


#body_en, #body_fr, #body_tr {
  .teaser-products,
  .frame-teaser-products {
    .teaser-product,
    .frame-teaser-product {
      figcaption a {
        text-indent: -115px;
      }
    }
  }
}
