div.teaser,
div.teaser-large,
div.frame-teaser,
div.frame-teaser-large {
  img {
    border-bottom-right-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-bottom-right-radius: 15px;
  }
}

div.teaser,
div.teaser-large,
div.frame-teaser,
div.frame-teaser-large {
  .csc-header {
    border-top-left-radius: 15px;
    -webkit-border-top-left-radius: 15px;
    -moz-border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-top-right-radius: 15px;
    font-size: 14px;
  }
}

div.teaser,
div.frame-teaser {
  img {
    border-bottom-left-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-bottom-left-radius: 15px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  width: 460px;
  height: 220px;
  float: left;
  background-color: #CFCED9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

div.teaser-large,
div.frame-teaser-large {
  .csc-header {
    width: 460px;
    height: 25px;
    line-height: 25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: left;
    background-color: #990000;
    padding-left: 10px;
    border-top-left-radius: 15px;
    -webkit-border-top-left-radius: 15px;
    -moz-border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-top-right-radius: 15px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  .csc-header h1 {
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    margin-top: 0px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  .image img {
    height: 195px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  .ce-textpic {
    width: 460px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  .ce-textpic .ce-bodytext {
    width: 240px;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
  }
}

div.teaser-large,
div.frame-teaser-large {
  .ce-textpic .ce-gallery {
    width: 200px;
    float: right;
    margin-left: 10px;
  }
}

div.teaser,
div.frame-teaser {
  margin-left: 23px;
}

div.teaser,
div.teaser-img,
div.frame-teaser,
div.frame-teaser-img {
  width: 220px;
  height: 220px;
  float: left;
  background-color: #CFCED9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  overflow: hidden;
}

div.teaser,
div.teaser-img,
div.frame-teaser,
div.frame-teaser-img {
  .csc-header {
    width: 220px;
    height: 25px;
    line-height: 25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: left;
    background-color: #C0CCDA;
    padding-left: 10px;
  }
}

div.teaser,
div.teaser-img,
div.frame-teaser,
div.frame-teaser-img {
  .csc-header h1 {
    font-size: 14px;
    font-weight: normal;
    color: #666;
    margin-top: 0px;
  }
}

div.teaser-img,
div.frame-teaser-img {
  background-color: #fff;
}

div.teaser-img,
div.frame-teaser-img {
  .csc-header {
    display: none;
  }
}

div.teaser-img,
div.frame-teaser-img {
  img {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border: solid 1px #ccc;
  }
}

div.image-large {
  width: 464px;
  float: left;
  min-height: 200px;

  .image img {
    border-radius: 15px;
    border: solid 1px #ccc;
    border-radius: 15px;
  }
}

div.speakerbox {
  float: right;
  width: 460px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

div.speakerbox-large {
  width: 700px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

div.messe {
  width: 700px;
  margin: 0;
  margin-top: 20px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;

  ul {
    padding: 20px 20px 20px 5px;
  }
}

div.info {
  width: 220px;
  height: 220px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid 1px #ccc;
  display: block;
  background-color: #eee;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  float: left;
  margin-left: 20px;

  .csc-header {
    width: 220px;
    min-height: 25px;
    background-color: #C0CCDA;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 7px 0 5px 10px;
    cursor: pointer;
  }

  .csc-header h1 {
    font-size: 12px;
    color: #333;
    font-weight: normal;
  }

  .csc-menu {
    width: 220px;
    height: 193px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: 25px;
    left: 0px;
    background-color: #F4F4F4;
    overflow: hidden;
    overflow-y: scroll;
    scroll: y-scroll;
    z-index: 3;
    font-size: 12px;
    line-height: 18px;
    list-style-type: square;
  }

  .overlay-img {
    width: 220px;
    height: 193px;
    position: absolute;
    top: 25px;
    left: 0px;
    background: url(img('shared/infobg.png'));
    cursor: pointer;
    z-index: 100;
  }

  li {
    margin-top: 5px;
    margin-left: 10px;
    width: 180px;
    min-height: 20px;
  }

  a {
    width: 180px;
    min-height: 20px;
    display: block;
    text-decoration: none;
  }
}

div.product,
div.productsmall,
div.teaser,
div.teaser-large,
div.frame-teaser,
div.frame-teaser-large {
  a {
    font-size: 14px;
    color: #990000;
    text-decoration: none;

    &:after {
      content: url(img('shared/arrow_more.png'));
    }
  }

  .image a:after {
    content: none;
  }
}

div.product,
div.productsmall {
  width: 220px;
  height: 220px;
  margin-bottom: 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid 1px #ccc;
  display: block;
  background-color: #eee;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  float: left;

  .csc-header {
    width: 218px;
    min-height: 45px;
    background-color: #C0CCDA;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 7px 0 5px 10px;
    cursor: pointer;
  }

  .csc-header h1 {
    color: #333;
    font-size: 12px;
    font-weight: normal;
  }

  .image {
    width: 218px;
    height: 203px;
    position: absolute;
    top: 35px;
    left: 0px;
    z-index: 100;
    cursor: pointer;
  }

  .ce-bodytext {
    height: 183px;
    width: 218px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: 35px;
    left: 0px;
    background-color: #F4F4F4;
    overflow: hidden;
    overflow-y: scroll;
    scroll: y-scroll;
    z-index: 3;
    font-size: 12px;
    line-height: 18px;
  }

  .ce-bodytext ul {
    list-style-type: square;
    margin-left: 10px;
  }

  .ce-bodytext,
  .ce-bodytext-text {
    li {
      margin: 0;
      margin-top: 5px;
      width: 180px;
      min-height: 20px;
    }
  }

  .ce-bodytext p {
    line-height: 20px;
    color: #333;
    font-size: 12px;
    margin-top: 10px;
  }

  ~ div.product,
  ~ div.productsmall {
    margin-left: 21px;
  }
}

div.productsmall {
  height: 220px;

  .csc-header {
    min-height: 25px;
  }

  .image {
    top: 25px;
  }

  .ce-bodytext {
    top: 25px;
  }

  .ce-bodytext {
    height: 193px;
  }
}

div.producttext {
  width: 464px;
  float: left;
  min-height: 200px;
}

#content3-container-product {
  width: 700px;
  float: right;

  div.product:nth-of-type(5n+1),
  div.productsmall:nth-of-type(5n+1) {
    margin-left: 20px;
  }

  div.product:nth-of-type(3n+1),
  div.productsmall:nth-of-type(3n+1) {
    margin-left: 0;
  }
}

div.content-aside {
  width: 706px;
  float: left;
  min-height: 200px;
}

div.aside {
  float: left;
  min-height: 200px;
  width: 460px;
  margin: 0 auto;
  margin-left: 24px;
  border: solid 1px #ccc;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  background-color: #eee;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.ce-gallery {
  list-style-type: none !important;

  li {
    margin: 0 !important;
  }
}
