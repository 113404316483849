#nav-main {
  width: 1190px;
  height: 30px;
  margin: 0 auto;


  > ul {
    list-style-type: none;
    width: 1190px;
    margin: 0 auto;
    position: relative;
  }

  > ul > li {
    width: 170px;
    height: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    float: left;
    background-color: #C0CCDA;
    border-left: solid 1px #fff;
    border-right: solid 1px #7E97B4;
    width: 170px;
    height: 30px;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-top: 8px;
    text-decoration: none;
    color: #333;
    cursor: pointer;
  }

  > ul > li:first-child {
    border-left: none;
  }

  > ul > li:last-child {
    border-right: none;
  }

  > ul > li.active {
    color: #fff;
    background-color: #990000;
  }

  > ul > li:hover {
    color: #fff;
    background-color: #005798;
  }

  > ul > li.focus {
    color: #fff;
    background-color: #005798;
  }

  .submenu {
    width: 1190px;
    border: solid 1px #ccc;
    background: rgba(255, 255, 255, 0.9);
    z-index: 300;
    position: absolute;
    top: 30px;
    left: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 2px 7px #999;
    display: none;

    a {
      width: 170px;
      min-height: 25px;
      display: block;
      text-decoration: none;
      padding: 5px 0;
      padding-left: 2px;
      color: #333;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      line-height: 15px;
      font-weight: bold;
      text-align: left;

      &:hover {
        background-color: #ccc;
      }
    }

    > a {
      font-size: 16px;
      font-weight: bold;
      color: #990000;
      text-decoration: none;
      outline: none;
      border: none;
      width: 1180px;
      text-align: left;
      padding-left: 20px;


      &:hover {
        background: none;
      }
    }

    li {
      float: left;
      width: 170px;
      min-height: 25px;
    }

    > ul > li {
      margin-left: 20px;
    }

    .subsubmenu a {
      width: 170px;
      min-height: 25px;
      display: block;
      padding-left: 10px;
      font-weight: normal;
      color: #000;
    }

    .subsubsubmenu li a {
      width: 170px;
      min-height: 20px;
      display: block;
      font-weight: normal;
      padding-left: 15px;
      color: #666;
      word-break: initial;
    }
  }
}
