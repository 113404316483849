/* Default style sheet for SrLanguageMenu */
.tx-srlanguagemenu {
  &, .links-list-header {
    font-family: Verdana, sans-serif;
    line-height: 100%;
    margin-top: 8px;
    padding: 0;
    text-indent: 0;
  }

  .CUR,
  .NO,
  .INACT {
    float: left;
    font-size: .8em;
    margin: 0 5px;

    a {
      background-color: transparent;
      font-weight: bold;
      text-decoration: none;

      &:focus,
      &:hover,
      &:active {
        color: #333333;
      }
    }
  }

  .CUR a.flag:before,
  .CUR a.linked-language:before {
    content: url(img('languages/current.png'));
    padding-right: 3px;
  }

  &-links .SPC:before {
    content: "|";
    padding-right: 3px;
  }

  .tx-srlanguagemenu-flag-linked,
  .tx-srlanguagemenu-flag-unlinked {
    height: 13px;
    width: 20px;
  }

  img.tx-srlanguagemenu-flag-unlinked {
    filter: grayscale(100%); /* Current draft standard */
    -webkit-filter: grayscale(100%); /* New WebKit */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
    filter: url("data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'><filter%20id='grayscale'><feColorMatrix%20type='matrix'%20values='0.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200%200%200%201%200'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
  }

  fieldset {
    legend {
      /*background: url(img('Images/languages/language.png')) no-repeat 2px 50%;
      padding: 0 2px 2px 20px;*/
    }

    label {
      display: none;
    }

    img.tx-srlanguagemenu-language-icon {
      margin-right: 3px;
      vertical-align: middle;
    }

    select {
      background-color: #009933;
      color: white;
      font-size: .8em;
    }

    option {
      padding: 2px 5px 0 2px;
    }
  }
}