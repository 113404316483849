#slider-container .nivo-caption {
  display: none !important;
}

#slider-container {
  &.slider-body {
    width: 700px;
    height: auto;
    float: left;
    position: relative;
    background-color: #eee;
    margin-bottom: 45px;
  }

  &.slider-body .slider-wrapper {
    height: auto !important;
    padding-top: 25px;
    position: relative;
    background-color: #fff;
    overflow: visible;
  }

  &.slider-body .slider-wrapper img {
    border: solid 1px #ccc;
  }

  &.slider-body .nivo-directionNav {
    display: none;
  }

  &.slider-body .nivo-controlNav {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
  }

  &.slider-body .nivo-controlNav a {
    text-indent: -10000px;
    width: 20px;
    height: 10px;
    background: none;
    background-color: #ccc;
    display: block;
    float: left;
    margin: 0 !important;
    margin-left: 5px !important;
    margin-top: 10px !important;
    outline: none;
    border: none;
  }

  &.slider-body .nivo-controlNav a.active {
    background-color: #990000;
  }

  &.slider-body .nivo-caption {
    display: block !important;
    width: 702px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    display: block;
    background-color: #666;
    padding: 0;
    padding-left: 10px;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    position: absolute;
    bottom: -25px;
  }

  &.slider-body .nivo-caption p {
    color: #fff;
    font-size: 13px;
    padding: 0;
    font-weight: normal;
    padding: 3px 0;
  }

  &.slider-body .nivo-caption a.hover {
    text-decoration: underline;
  }

  &.slider-body .nivoSlider {
    overflow: visible;
  }
}

#slider .theme-product {
  background: none;
}

#main-container {
  &.bodyslider #content1-container {
    width: 464px;
    float: left;
    min-height: 200px;
  }

  &.bodyslider #content2-container {
    width: 706px;
    float: left;
    margin-left: 20px;
    min-height: 200px;
  }

  &.bodyslider #content3-container {
    width: 1190px;
  }
}

.slider-wrapper {
  background: transparent url(img('nivo/loading.gif')) no-repeat 50% 50%;
}

.nivoSlider {
  position: relative;
  /*	background:#fff url(loading.gif) no-repeat 50% 50%;*/
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 1px 5px 0px #4a4a4a;
  -moz-box-shadow: 0px 1px 5px 0px #4a4a4a;
  box-shadow: 0px 1px 5px 0px #4a4a4a;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
  }

  a {
    border: 0;
    display: block;
  }
}

.nivo-controlNav {
  text-align: center;
  margin-top: -10px;
  padding: 0;

  a {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(img('nivo/bullets.png')) no-repeat;
    text-indent: -9999px;
    border: 0;
    margin: 0 2px;

    &.active {
      background-position: 0 -22px;
    }
  }
}

.nivo-directionNav a {
  display: block;
  width: 30px;
  height: 30px;
  background: url(img('nivo/arrows.png')) no-repeat;
  text-indent: -9999px;
  border: 0;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

div.slider-wrapper:hover .nivo-directionNav a {
  opacity: 1;
}

a.nivo-nextNav {
  background-position: -30px 0;
  right: 15px;
}

a.nivo-prevNav {
  left: 15px;
}

.nivo-caption a {
  color: #fff;
  border-bottom: 1px dotted #fff;
}

.nivo-caption a:hover {
  color: #fff;
}

.nivo-controlNav {
  &.nivo-thumbs-enabled {
    width: 100%;
  }

  &.nivo-thumbs-enabled a {
    width: auto;
    height: auto;
    background: none;
    margin-bottom: 5px;
  }

  &.nivo-thumbs-enabled img {
    display: block;
    width: 120px;
    height: auto;
  }
}
